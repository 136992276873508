import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { DashboardService } from "../dashboard.service";
import { DataStoreServiceService } from "../data-store-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { ReturnWaitingRoomComponent } from "../return-waiting-room/return-waiting-room.component";
import { MatDialog } from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { ModalService } from "../modal.service";
import { FormGroup } from "@angular/forms";
import { QbService } from "../qb.service";
import { catchError, concatMap, Subscription as RxSubscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { CredentialsService } from "src/app/core/credentials.service";
import { SharedService } from "src/app/shared/shared.service";
import { Location } from "@angular/common";
import { CallStateService } from "src/app/shared/call-state/call-state.service";
import { ScheduleFollowUpConsultationComponent } from "../schedule-follow-up-consultation/schedule-follow-up-consultation.component";
import { SubscriptionDetails } from "src/app/core/subscriptions.spec";

import type { RecordedVideoOutput } from "../video/video.model";
import { SucessPopupComponent } from "../sucess-popup/sucess-popup.component";
import { ConfirmEmConsultationComponent } from "../confirm-em-consultation/confirm-em-consultation.component";
//import { title } from "process";
import { SpecialtiesService } from "src/app/core/specialties.service";
import { CleverTapService } from "src/app/clevertap.service";

// appointmentType
export interface ConsultationDetails {
  data: Datum[];
  meta: Meta;
}

export interface Datum {
  _id: string;
  chiefComplaint: DatumChiefComplaint;
  document: any[];
  informedConsent: boolean;
  patient: Patient;
  parent: Parent;
  status: number;
  consultationType: number;
  appointmentType: number;
  appointmentStatus: number;
  appointmentRescheduledCount: number;
  appointmentRescheduledByDoctorCount: number;
  action: any[];
  paraclinicalPrescription: any[];
  medicalPrescription: any[];
  providerCall: string;
  createdAt: Date;
  updatedAt: Date;
  communicationMode: number;
  date: Date;
  provider: string;
  utcOffset: number;
  medicalSpecialty: any;
}

export interface DatumChiefComplaint {
  id: string;
  chiefComplaint: ChiefComplaintChiefComplaint;
}

export interface ChiefComplaintChiefComplaint {
  en: string;
  es: string;
}

export interface Parent {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  identificationNumber: string;
  countryCode: string;
  profilePic: string;
}

export interface Patient {
  _id: string;
  firstName: string;
  lastName: string;
  profilePic: string;
  identificationNumber: string;
}

export interface Meta {
  fileUrl: string;
  videoRecordingFileUrl: string;
}

export interface AppointmentType {
  DEMAND: number;
  TRIAGE: number;
  SCHEDULED: number;
}

export enum ValueAppointmentType {
  DEMAND = 1,
  TRIAGE = 2,
  SCHEDULED = 3,
}

export interface PatientDetails {
  consultation: Consultation;
  patient: PatientClass;
  medicalHistory: MedicalHistory;
  document: Document[];
  visitHistory: any[];
  medicalPrescription: any[];
}

export interface Consultation {
  _id: string;
  chiefComplaint: ChiefComplaint;
  document: any[];
  informedConsent: boolean;
  patient: PatientEnum;
  parent: ParentEnum;
  status: number;
  consultationType: number;
  appointmentType: number;
  appointmentStatus: number;
  appointmentRescheduledCount: number;
  appointmentRescheduledByDoctorCount: number;
  action: any[];
  paraclinicalPrescription: any[];
  medicalPrescription: any[];
  providerCall: string;
  createdAt: Date;
  updatedAt: Date;
  communicationMode: number;
  date: Date;
  provider: string;
  utcOffset: number;
}

export interface ChiefComplaint {
  id: string;
  chiefComplaint: TagClass;
}

export interface TagClass {
  en: En;
  es: Es;
}

export enum En {
  Earache = "Earache",
  Mediktor = "Mediktor",
}

export enum Es {
  DolorDeOídos = "Dolor de oídos",
  Mediktor = "Mediktor",
}

export enum ParentEnum {
  The61154196E155F56975Ebaadc = "61154196e155f56975ebaadc",
}

export enum PatientEnum {
  The65E73E684Cbf6539C6C6B9Ea = "65e73e684cbf6539c6c6b9ea",
}

export interface Document {
  _id: string;
  status: number;
  patient: PatientEnum;
  name: Name;
  description: Description;
  url: string;
  tag: Tag;
  parent: ParentEnum;
  createdAt: Date;
  updatedAt: Date;
  id: string;
}

export enum Description {
  ReporteMediktor = "Reporte Mediktor",
}

export enum Name {
  Mediktor08_11_2024PDF = "Mediktor_08_11_2024.pdf",
}

export interface Tag {
  id: ID;
  tag: TagClass;
}

export enum ID {
  The6720D5D28D9Ae637D1A9C8Fb = "6720d5d28d9ae637d1a9c8fb",
}

export interface MedicalHistory {}

export interface PatientClass {
  _id: PatientEnum;
  resetStatus: number;
  status: number;
  visitPWA: boolean;
  callMade: boolean;
  isFreeTrialUsed: boolean;
  shippingCounterEmail: number;
  shippingCounterSms: number;
  typeRegister: number;
  firstName: string;
  lastName: string;
  gender: number;
  dateOfBirth: Date;
  relationship: number;
  profilePic: string;
  identificationNumber: string;
  parent: ParentClass;
  createdAt: Date;
  updatedAt: Date;
  termsAndConditionsAccepted: boolean;
  agreementAccepted: AgreementAccepted;
  canSubscribe: boolean;
  tryAndBuyAccepted: boolean;
  tryAndBuyCountDecline: number;
  tryAndBuyOrigin: number;
  tryAndBuyUsed: boolean;
  countryCode?: string;
  phoneNumber?: string;
}

export interface AgreementAccepted {
  termsAndConditionsAccepted: boolean;
  termsAndConditionsTBAccepted: boolean;
  privacyPolicyAccepted: boolean;
}

export interface ParentClass {
  _id: ParentEnum;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  identificationNumber: string;
  countryCode: string;
  quickbloxId: string;
  profilePic: string;
  subscription: string;
}

export interface Subscription {
  status: number;
  provider: Provider;
}

export interface Provider {
  type: number;
  id: string;
  provider: string;
}

export enum StatusCall {
  DRAFT = 0,
  WAITING = 1,
  ACTIVE = 2,
  CALL_STARTED = 3,
  CALL_COMPLETED = 4,
  COMPLETED = 5,
  CANCELLED = 6,
  DELETED = 7,
}

@Component({
  selector: "app-consults-page",
  templateUrl: "./consults-page.component.html",
  styleUrls: ["./consults-page.component.scss"],
})
export class ConsultsPageComponent implements OnInit, OnDestroy {
  @ViewChild("visitsummary", { read: ElementRef })
  visitSummaryElement: ElementRef;
  @ViewChild("medicalhistory", { read: ElementRef })
  medicalHistoryElement: ElementRef;
  @ViewChild("attachments", { read: ElementRef })
  attachmentsElement: ElementRef;
  @ViewChild("medication", { read: ElementRef }) medicationElement: ElementRef;
  @ViewChild("visithistory", { read: ElementRef })
  visitHistoryElement: ElementRef;

  @ViewChild("componentContainer", { static: true })
  compContainerElement: ElementRef;
  public currentActive = 0;
  public visitSummaryOffset: Number = null;
  public medicalHistoryOffset: Number = null;
  public attachmentsOffset: Number = null;
  public medicationOffset: Number = null;
  public visitHistoryOffset: Number = null;
  public mainScrollOffset: number = null;

  activeState = "Medical History";
  public singPatientRecord: any;
  public consultDetail: any;
  public attachmentOnCallRecord: any;
  minDate: Date = new Date();
  consultId: any;
  age: number;
  bmi: number;
  displayBMI: string;
  startVideoCall: boolean = false;
  startAudioCall: boolean = false;
  startChat: boolean = false;
  loading: boolean = true;
  consultComplete: boolean = false;
  saveExit: boolean = false;
  connectPatient: boolean = false;
  saveExitEnable: boolean = false;
  disabled: boolean = true;
  followCall = false;
  followCallForm: FormGroup;
  follow: any;
  followCallingUp: boolean = false;
  followCallingDate: any;
  connectTodisabled: boolean = true;
  qbchatConnectionForVideo: string = "start";
  subscriptions: RxSubscription = new RxSubscription();
  patientId: string;
  currentLanguage: string = "es";
  fileUrl: string;
  countryCode: string;
  phoneNumber: string;
  currentSection: string = "visitHistory";
  memberships: string[] = ["Movistar", "Movilnet", "Digitel"];
  showOverlayAffiliationsView = false;
  subscriptionsElements: SubscriptionDetails[] = [];

  transfer: boolean = false;
  normalConsult: boolean = true;
  connectToPatientEM: boolean = true;

  public ValueAppointmentType = ValueAppointmentType;
  public ConsultDetailStatus = StatusCall;

  constructor(
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private store: DataStoreServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private qbService: QbService,
    private modalService: ModalService,
    private credService: CredentialsService,
    private translateService: TranslateService,
    private sharedService: SharedService,
    private _location: Location,
    private callStateService: CallStateService,
    private specialtiesService: SpecialtiesService,
    private readonly clevertap: CleverTapService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.consultId = params.id;
      this.patientId = params.patient;
    });
  }

  ngOnInit() {
    if (this.translateService?.currentLang == "en-US") {
      this.currentLanguage = "en";
    }

    this.fileUrl = this.credService.credentials.fileUrl + "/";
    this.patientContainerDetails(this.consultId);

    // Tracking the call status
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.subscriptions.add(
      this.modalService.disconnectCallObservable.subscribe({
        next: (res: any) => {
          if (res.callStatus == "disconnected") {
            this.startVideoCall = false;
            this.startAudioCall = false;
            this.startChat = false;

            this.callStateService.updateCallState(false);
          }
        },
      }),
    );

    // Reconnect mechanism handling
    this.subscriptions.add(
      this.modalService.reconnectObservable.subscribe({
        next: (res: any) => {
          this.connectToPatient(res.status);
        },
      }),
    );

    this.subscriptions.add(
      this.modalService.vscallStatusObservable.subscribe({
        next: (res: any) => {
          this.saveExit = true;
          this.consultComplete = false;
          this.connectPatient = false;
        },
      }),
    );

    this.subscriptions.add(
      this.modalService.callFeedBackObservable.subscribe((res) => {
        if (res.callStatus == 2) {
          this.disabled = false;
        }
      }),
    );

    this.modalService.getSubscriptions(this.patientId).subscribe({
      next: (subscriptionElements: any) => {
        // this.isLoading = false;
        this.subscriptionsElements = subscriptionElements;
      },
      error: (error: HttpErrorResponse) => {
        // this.isLoading = false;
        this.handleError(error);
      },
    });

    if (sessionStorage.getItem("chatConnection") == "finish") {
      this.qbchatConnectionForVideo = "finish";
      this.connectTodisabled = false;
    }

    this.subscriptions.add(
      this.qbService.qbListenerObservable.subscribe((res: any) => {
        if (res == true) {
          this.qbchatConnectionForVideo = "finish";
          this.connectTodisabled = false;
        }
      }),
    );

    this.subscriptions.add(
      this.callStateService.getErrorObservable().subscribe({
        next: (res: Boolean) => {
          if (res) {
            this.startVideoCall = false;
            this.startAudioCall = false;
            this.startChat = false;
          }
        },
      }),
    );
  }

  patientContainerDetails(id: string) {
    this.subscriptions.add(
      this.dashboardService.getConsultationDetails(id).subscribe({
        next: (res: ConsultationDetails) => {
          const [consultation] = res.data;

          localStorage.setItem(
            "patientDetails",
            JSON.stringify(consultation.patient),
          );

          this.consultDetail = consultation;

          if (consultation?.appointmentType !== 3) {
            this.connectToPatientEM = false;
          }
          
          if (
            consultation?.appointmentType === 3 &&
            [
              StatusCall.CALL_COMPLETED, 
              StatusCall.COMPLETED, 
              StatusCall.DRAFT
            ].includes( consultation?.status )
          ) {
            this.connectToPatientEM = false;
          }
          
          if (consultation?.appointmentType === 2) {
            this.normalConsult = false;
          }

          this.consultDetail.specialtyTitle = this.getSpecialtyName(consultation);
        },
      }),
    );

    this.subscriptions.add(
      this.dashboardService.getPatientDetails(id).subscribe({
        next: (res: PatientDetails) => {
          this.singPatientRecord = res;

          if (res.patient.parent) {
            this.countryCode = res.patient.parent.countryCode;
            this.phoneNumber = res.patient.parent.phoneNumber;
          } else {
            this.countryCode = res.patient?.countryCode;
            this.phoneNumber = res.patient?.phoneNumber;
          }
          this.calculateAge(this.singPatientRecord.patient.dateOfBirth);
          
          if ( this?.singPatientRecord?.medicalHistory ) {

            this.calculateBMI(
              this.singPatientRecord.medicalHistory.height,
              this.singPatientRecord.medicalHistory.weight,
            );

          }
          
          if (this.singPatientRecord.consultation.status === StatusCall.COMPLETED) {
            this.consultComplete = true;
          } 
          else {
            this.consultComplete = false;
          }

          if (
            this.singPatientRecord.consultation.status === StatusCall.CALL_COMPLETED
          ) {
            this.saveExit = true;
            this.disabled = false;
          } else {
            this.saveExit = false;
          }
          if (
            [StatusCall.ACTIVE, StatusCall.CALL_STARTED].includes(
              this.singPatientRecord.consultation.status,
            )
          ) {
            this.connectPatient = true;
          } else {
            this.connectPatient = false;
          }

          this.store.setPatientRecord(this.singPatientRecord);
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
          this.router.navigate(["/"]);
        },
      }),
    );
  }

  get canCreateFollowUp(): boolean {
    const { consultation, patient, parentConsultation } = this.singPatientRecord;

    const hasPatientSubscription = !!patient.subscription;
    const hasParentSubscription = !!patient.parent?.subscription;
    const hasFollowUpDate = !!parentConsultation?.followUpDate3;

    if (hasPatientSubscription || hasParentSubscription) {
      return (
        (hasPatientSubscription && !hasFollowUpDate) ||
        (hasParentSubscription && !hasFollowUpDate)
      );
    }

    return !consultation.isFollowUp;
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
  setStateAsActive(link) {
    this.activeState = link.name;
  }

  calculateAge(dob: any) {
    this.age = moment().diff(dob, "years");
  }

  calculateBMI(height: number, weight: number) {
    if (weight > 0 && height > 0) {
      this.bmi = weight / (((height / 100) * height) / 100);
      this.displayBMI = this.bmi.toFixed(2);
    } else {
      this.bmi = null;
    }
  }
  openDialog(pattern = "stay") {
    const dialogRef = this.dialog.open(ReturnWaitingRoomComponent, {
      width: "429px",
      // height:'273px',
      disableClose: true,
      autoFocus: false,
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result === "return") {
          this._location.back();
        }
      }),
    );
  }

  connectToPatient(communicationMode: number) {
    if (
      this.consultDetail?.appointmentType === ValueAppointmentType.SCHEDULED &&
      this.consultDetail.status === StatusCall.WAITING
    ) {
      const startCall = this.dashboardService
        .lockConsultation(this.consultDetail._id)
        .pipe(
          concatMap((_: any) => {
            this.consultDetail = { ...this.consultDetail, status: 3 };

            return this.dashboardService
              .startCallByDoctor(this.consultId, {
                communicationMode: communicationMode,
              })
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  this.handleError(error);
                  return [];
                }),
              );
          }),
          catchError((error: HttpErrorResponse) => {
            this.handleError(error);
            return [];
          }),
        )
        .subscribe((res: any) => {
          if (res?.length) {
            this.connectToPatientEM = false;
            switch (communicationMode) {
              case 1:
                this.startAudioCall = true;
                break;
              case 2:
                this.startVideoCall = true;
                break;
              case 3:
                this.startChat = true;
                break;
            }

            if (this.startAudioCall || this.startVideoCall || this.startChat) {
              this.callStateService.updateCallState(true);
            }
          }
        });

      this.subscriptions.add(startCall);

      // this.subscriptions.add(
      //   this.dashboardService.getConsultation(this.consultDetail._id).subscribe({
      //       next: (res: any) => {
      //         this.consultDetail = { ...this.consultDetail, status: 3 };
      //         this.subscriptions.add(
      //           this.dashboardService.startCallByDoctor(this.consultId, {
      //             communicationMode: communicationMode,
      //           })
      //             .subscribe({
      //               next: (res: any) => {
      //                 console.log(res);

      //                 if (res) {
      //                   this.connectToPatientEM = false;
      //                   switch(communicationMode) {
      //                     case 1:
      //                       this.startAudioCall = true;
      //                       break;
      //                     case 2:
      //                       this.startVideoCall = true;
      //                       break;
      //                     case 3:
      //                       this.startChat = true;
      //                       break;
      //                   }

      //                   if ( this.startAudioCall || this.startVideoCall || this.startChat ) {
      //                     this.callStateService.updateCallState(true);
      //                   }
      //                 }
      //               },
      //               error: (error: HttpErrorResponse) => {
      //                 this.handleError(error);
      //               },
      //             }),
      //         );
      //       },
      //       error: (error: HttpErrorResponse) => {
      //         this.handleError(error);
      //       },
      //     }),
      // );
    } else {
      this.subscriptions.add(
        this.dashboardService
          .startCallByDoctor(this.consultId, {
            communicationMode: communicationMode,
          })
          .subscribe({
            next: (res: any) => {
              if (res) {
                this.connectToPatientEM = false;

                switch (communicationMode) {
                  case 1:
                    this.startAudioCall = true;
                    break;
                  case 2:
                    this.startVideoCall = true;
                    break;
                  case 3:
                    this.startChat = true;
                    break;
                }

                if (
                  this.startAudioCall ||
                  this.startVideoCall ||
                  this.startChat
                ) {
                  this.callStateService.updateCallState(true);
                }
              }
            },
            error: (error: HttpErrorResponse) => {
              this.handleError(error);
            },
          }),
      );
    }
  }

  openModalWithAfilliations() {
    this.showOverlayAffiliationsView = true;
  }

  closeModalWithAfilliations() {
    this.showOverlayAffiliationsView = false;
  }

  submitVisitSummary() {
    if (this.followCallingUp && !this.followCallingDate) {
      this.subscriptions.add(
        this.translateService
          .get(
            "Please select the date if you want followup call to be scheduled",
          )
          .subscribe((text: string) => {
            this.sharedService.showErrorMessage(text);
          }),
      );
      return;
    }
    
    this.modalService.sendVsFormData({
      hasFollowUp: this.followCallingUp,
      followUpDate: this.followCallingDate,
    });
  }

  setTransfer(transfer: boolean) {
    this.transfer = transfer;
    setTimeout(() => {
      this.submitVisitSummary();
    }, 500);
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  scrollTo(section) {
    document.querySelector("#" + section).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  openScheduleFollowUpConsultation() {
    const dialogRef = this.dialog.open(ScheduleFollowUpConsultationComponent, {
      width: "480px",
      disableClose: true,
      autoFocus: false,
      panelClass: "schedule-follow-up-dialog",
      data: {
        action: "create",
        consultId: this.consultId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event === "create") {
        this.followCallingUp = true;
        this.followCallingDate = result?.data?.followUpDate;
      }
    });
  }

  routeToBackState() {
    this.openDialog("return");
    this._location.back();
  }

  ngOnDestroy() {
    this.dialog.closeAll();
    this.subscriptions.unsubscribe();

    // this.dashboardService.postponeCallByDoctor(this.consultId).subscribe({
    //   next:(res: any) => {
    //     this.translateService
    //       .get("Call has been Postponed Successfully")
    //       .subscribe((text: string) => {
    //         this.sharedService.showMessage(text, "success");
    //       });

    //     this.router.navigate([""]);
    //   },
    //   error:(error: HttpErrorResponse) => {
    //     this.handleError(error);
    //   },
    // });
  }

  rescheduled(appointmentId: string) {
    const dialogRef = this.dialog.open(ConfirmEmConsultationComponent, {
      width: "600px",
      height: "380px",
      data: {
        title: "¿Estás seguro que deseas solicitar reagendar esta cita?",
        acceptAction: "Solicitar",
        cancelAction: "Cancelar",
        margin: true,
      },
      panelClass: "transfer-dialog",
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.specialtiesService
          .rescheduleAppointment(appointmentId, {
            appointmentStatusUpdatedBy: 2,
          })
          .subscribe({
            next: (res) => {
              this.sharedService.showMessage(
                "Se envió tu solicitud al agente exitosamente.",
                "success",
              );
              this.patientContainerDetails(this.consultId);
              this.clevertap.registerEvent$("reschedule_requested", {
                user_id: this.consultDetail.patient.identificationNumber,
                specialty: res.data.medicalSpecialty,
                requester: this.credService.credentials.userDetails._id,
              });
              this.connectToPatientEM = false;
            },
            error: (error) => {
              this.sharedService.showErrorMessage("ocurrio un error", "error");
            },
          });
      }
    });
  }

  getSpecialtyName(consultation: Datum): string {
    if (consultation?.appointmentType === ValueAppointmentType.TRIAGE) {
      if (consultation.medicalSpecialty?.subSpeciality) {
        return `Triaje para ${
          consultation.medicalSpecialty.subSpeciality.subSpeciality[
            this.currentLanguage
          ]
        } - `;
      }
      if (consultation.medicalSpecialty?.speciality) {
        return `Triaje para ${
          consultation.medicalSpecialty.speciality.speciality[
            this.currentLanguage
          ]
        } - `;
      }
    }

    if (consultation?.appointmentType === ValueAppointmentType.SCHEDULED) {
      if (consultation.medicalSpecialty?.subSpeciality) {
        return `${
          consultation.medicalSpecialty.subSpeciality.subSpeciality[
            this.currentLanguage
          ]
        } - `;
      }
      if (consultation.medicalSpecialty?.speciality) {
        return `${
          consultation.medicalSpecialty.speciality.speciality[
            this.currentLanguage
          ]
        } - `;
      }
    }

    return "";
  }
}
