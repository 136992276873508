<div class="modal-container-memberships">
  <div class="modal-container-close-button">
    <button (click)="closeModal()">
      <img src="../../../assets/images/close-cancel.svg" alt="" />
    </button>
  </div>
  <span class="modal-container-memberships-title">{{
    "Afiliaciones" | translate
  }}</span>
  <span class="modal-container-memberships-subtitle">{{
    "The patient is affiliated with the following entities:" | translate
  }} </span>

  <div style="height: 145px; overflow: auto">
    @for ( membership of subscriptionsElements; track membership.name ) {
      <div class="modal-memberships-container">
        <p>
          @switch ( membership.status ) {
            @case(1) {
              <span class="modal-container-span-active">
                {{ "activa" | translate }}
              </span>
            }
            @case (2) {
              <span class="modal-container-span-active">
                <img
                  src="../../../assets/images/membership-check.svg"
                  alt="membership-check"
                />
              </span>
            }
          }
          <span class="modal-container-span-title">
            {{ membership.name }}
          </span>
        </p>
    </div>
    }
  </div>

  <div class="modal-container-button-parent">
    <button
      (click)="closeModal()"
      class="modal-container-button"
    >
      Ok
    </button>
  </div>
</div>