<div *ngIf="visitSummary">
  <!-- <div> -->
  <div id="#enableSummary" class="switch-config-button">
    <h5 class="title" *ngIf="!loading">
      {{ "Is this Test call?" | translate }}
      <mat-slide-toggle
        [color]="'primary'"
        style="text-align: center; padding-left: 15%"
        class="test-call-toggle"
        (change)="testCalling($event.checked)"
      >
      </mat-slide-toggle>
    </h5>
  </div>
  <br /><br />
  <div *ngIf="!isTestCall">
    <!-- <button
      type="button"
      style="
        background: none;
        float: right;
        margin-top: -10px;
        border: none;
        cursor: pointer;
        color: #65a7f6;
      "
      (click)="enableUpdateBtn()"
      [disabled]="false"
      [ngClass]="'show-btn'"
    >
      <span style="vertical-align: middle">
        <img
          class="icon"
          style="vertical-align: middle"
          src="assets/images/editi.svg"
        />
        {{ "Edit" | translate }}</span
      >
    </button> -->

    <div class="container">
      <h5 class="title">{{ "Visit Summary" | translate }}</h5>
      <form [formGroup]="form" (ngSubmit)="formSubmit()">
        <!-- Chief Complaint -->
        <div>
          <mat-card class="cards">
            <h6 class="subheading">{{ "Chief Complaint" | translate }}</h6>
            <p class="sub-specialty">
              {{ getSpecialtyName(currentVisitDetails) }}
            </p>
            <div>
              <!-- <h5 class="heading">{{ "Reason for visit" | translate }}</h5> -->
              <span class="sub-headings">
                <mat-form-field
                  style="width: 25%"
                  class="my-form-field"
                  *ngIf="chiefComplaintList"
                >
                  <!-- <mat-label>{{ "Reason for visit" | translate }}</mat-label>

                  <mat-select formControlName="chiefComplaint">
                    <mat-option
                      *ngFor="let c of chiefComplaintList"
                      [value]="c"
                      >{{ c.chiefComplaint[currentLanguage] }}</mat-option
                    >
                  </mat-select> -->

                  <mat-label>{{ "Reason for visit" | translate }}</mat-label>
                  <input
                    type="text"
                    aria-label="Number"
                    matInput
                    formControlName="chiefComplaint"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option
                      *ngFor="let c of filteredOptions | async"
                      [value]="c"
                      >{{ c }}</mat-option
                    >
                  </mat-autocomplete>
                </mat-form-field>

                <!-- <mat-form-field
                  class="my-form-field"
                  style="width: 25%; padding-left: 2%"
                  *ngIf="showOtherChiefComplaint"
                >
                  <input
                    type="text"
                    matInput
                    placeholder="{{ 'Other ChiefComplaint' | translate }}"
                    formControlName="otherChiefComplaint"
                  />
                </mat-form-field> -->
              </span>
            </div>
            <br />
            <div>
              <h5 class="heading">{{ "Pregnancy status" | translate }}</h5>
              <ng-container [ngSwitch]="currentVisitDetails?.isPregnant">
                <span class="sub-headings" *ngSwitchCase="1">{{
                  "Yes" | translate
                }}</span>
                <span class="sub-headings" *ngSwitchCase="2">{{
                  "No" | translate
                }}</span>
                <span class="sub-headings" *ngSwitchCase="3">{{
                  "Not sure" | translate
                }}</span>
              </ng-container>
            </div>
            <br />
            <div>
              <h5 class="heading">{{ "Photos" | translate }}</h5>
              <div class="row">
                <div class="column">
                  <span
                    *ngFor="
                      let a of currentVisitDetails.document;
                      let i = index
                    "
                  >
                    <span (click)="attachmentPopup(a)">
                      <img
                        *ngIf="documentsGetImage(a) === 'jpg'"
                        src="{{ a.url }}"
                        style="width: 100px; height: 100px; margin: 10px"
                      />
                      <img
                        *ngIf="documentsGetImage(a) === 'png'"
                        src="{{ a.url }}"
                        style="width: 100px; height: 100px; margin: 10px"
                      />
                      <img
                        *ngIf="documentsGetImage(a) === 'pdf'"
                        src="../../../assets/images/pdf-view.png"
                        style="width: 100px; height: 100px; margin: 10px"
                      />
                      <img
                        *ngIf="documentsGetImage(a) === 'doc'"
                        src="../../../assets/images/word.svg"
                        style="width: 100px; height: 100px; margin: 10px"
                      />
                      <img
                        *ngIf="documentsGetImage(a) === 'xsl'"
                        src="../../../assets/images/excel.svg"
                        style="width: 100px; height: 100px; margin: 10px"
                      />
                    </span>
                  </span>
                  <div *ngIf="currentVisitDetails.document < 1">
                    {{ "No Photos Available" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <!-- History of present illness-->
        <div>
          <mat-card class="cards">
            <!-- <h6 class="subheading">
              {{ "History of present illnesses" | translate }}
            </h6> -->
            <!-- <div> -->
            <!-- <mat-form-field style="width: 100%" appearance="outline">
              <textarea
                matInput
                class="historyarea"
                rows="5"
                cols="120"
                placeholder="{{ 'Enter Text....' | translate }}"
                formControlName="historyOfPresentIllness"
                [(ngModel)]="historyOfPresentIllness"
              ></textarea>
            </mat-form-field> -->
            <app-text-area
              controller="historyOfPresentIllness"
              [data]="historyOfPresentIllness"
              label="History of present illnesses"
              (change)="setHistoryOfPresentIllness($event)"
              [edit]="isRegularFormEnabled"
              [valid]="isPresentIllnessValid"
              [style]="'normal'"
            ></app-text-area>
            <!-- </div> -->
          </mat-card>
        </div>

        <mat-card class="review-of-system-container">
          <h6 class="subheading">{{ "Review Of System" | translate }}</h6>

          <div formArrayName="reviewOfSystem" *ngIf="form">
            <div
              *ngFor="
                let P of form['controls'].reviewOfSystem['controls'];
                let ir = index
              "
            >
              <div formGroupName="{{ ir }}" class="review-of-system-grid">
                
                <mat-form-field style="width: 20%" class="my-form-field">
                  <mat-label>{{ "Problems" | translate }}</mat-label>
                  <mat-select formControlName="bodySystem">
                    <mat-option
                      *ngFor="let system of bodysystem"
                      [value]="system._id"
                    >
                      {{ system.bodySystem[currentLanguage] }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                
                <mat-form-field class="my-form-field" style="width: 65%">
                  <mat-label>{{ "Tell What.." | translate }}</mat-label>
                  <input matInput formControlName="value" />
                </mat-form-field>
                
                <span
                  class="circle"
                  *ngIf="
                    isRegularFormEnabled &&
                    form['controls'].reviewOfSystem['controls'].length - 1 == ir
                  "
                  (click)="addReviewOfSystem()"
                >
                  <!-- <img src="../../../assets/images/addnew.svg" /> -->
                  <mat-icon>
                    <img src="../../../assets/images/add.svg" />
                  </mat-icon>
                  <!-- <span style="padding-top: 2%; padding-left: 5px;">{{ "Add New" | translate}}</span> -->
                </span>
                <span
                  class="circle"
                  *ngIf="form['controls'].reviewOfSystem['controls'].length > 1"
                  (click)="removeReviewOfSystem(ir)"
                >
                  <!-- <img src="../../../assets/images/deletes.svg" /> -->

                  <mat-icon
                    ><img
                      style="margin-left: 5px"
                      src="../../../assets/images/delete-all.svg"
                  /></mat-icon>
                  <!-- <span style="padding-top: 2%; padding-left: 5px;">{{ "Delete" | translate}}</span> -->
                </span>
              </div>
            </div>
          </div>
        </mat-card>

        <!-- Vital Information-->
        <div class="vital">
          <mat-card class="cards">
            <h6 class="subheading">{{ "Vital signs" | translate }}</h6>
            <div style="display: flex">
              <mat-form-field class="my-form-field" style="width: 19%">
                <span matSuffix>{{ "mmHg" | translate }} &nbsp;</span>
                <mat-label>{{ "BPSystolic" | translate }}</mat-label>
                <input
                  type="number"
                  matInput
                  min="0"
                  placeholder=""
                  formControlName="bloodPressureSystolic"
                  (keypress)="_keyPress($event)"
                />
              </mat-form-field>

              <mat-form-field class="my-form-field" style="width: 19%">
                <mat-label>{{ "BPDiastolic" | translate }}</mat-label>
                <span matSuffix>{{ "mmHg" | translate }} &nbsp;</span>
                <input
                  type="number"
                  matInput
                  min="0"
                  placeholder=""
                  formControlName="bloodPressureDiastolic"
                  (keypress)="_keyPress($event)"
                />
              </mat-form-field>

              <mat-form-field class="my-form-field" style="width: 17%">
                <mat-label>{{ "HeartRate" | translate }}</mat-label>
                <span matSuffix>{{ "bpm" | translate }} &nbsp;</span>
                <input
                  type="number"
                  matInput
                  min="0"
                  placeholder=""
                  formControlName="heartRate"
                  (keypress)="_keyPress($event)"
                />
              </mat-form-field>
              <mat-form-field class="my-form-field" style="width: 19%">
                <mat-label>{{ "Respiratory" | translate }}</mat-label>
                <span matSuffix>{{ "rbm" | translate }} &nbsp;</span>
                <input
                  type="number"
                  matInput
                  min="0"
                  placeholder=""
                  formControlName="respiratoryRate"
                  (keypress)="_keyPress($event)"
                />
              </mat-form-field>
              <mat-form-field class="my-form-field" style="width: 12%">
                <mat-label>{{ "Temperature" | translate }}</mat-label>
                <span matSuffix>{{ "`C" | translate }} &nbsp;</span>
                <input
                  type="number"
                  matInput
                  min="0"
                  placeholder=""
                  formControlName="temperature"
                  (keypress)="_keyPress($event)"
                />
              </mat-form-field>
            </div>
          </mat-card>
        </div>

        <!-- Medical Information -->
        <div>
          <mat-card class="cards">
            <h6 class="subheading margin-bottom">
              {{ "Medical Information" | translate }}
            </h6>
            <!-- <mat-form-field class="my-form-field" style="width: 91%">
              <input
                matInput
                placeholder="{{ 'Paraclinics' | translate }}"
                formControlName="paraclinics"
              />
            </mat-form-field> -->
            <app-text-area
              controller="paraclinics"
              [data]="paraclinics"
              label="{{ 'Paraclinics' | translate }}"
              (change)="setParaclinics($event)"
              [style]="'normal'"
              [edit]="isRegularFormEnabled"
            ></app-text-area>
            <!-- <mat-form-field class="my-form-field" style="width: 91%">
              <input
                matInput
                placeholder="{{ 'Differential Diagnosis' | translate }}"
                formControlName="differentialDiagnosis"
              />
            </mat-form-field> -->

            <!-- TODO -->

            <mat-form-field style="width: 100%" class="my-form-field">
              <mat-label>{{ "Differential Diagnosis" | translate }}</mat-label>
              <input
                type="text"
                aria-label="string"
                matInput
                formControlName="differentialDiagnosisCie11"
                (ngModelChange)="onChangeDifferentDiagnosisCie11($event)"
                [matAutocomplete]="diffAuto"
              />

              <mat-autocomplete #diffAuto="matAutocomplete">
                <mat-option
                  (click)="
                    addDiagnosis(
                      diagnostic,
                      differentialDiagnosisListSelected,
                      'differentialDiagnosisCie11'
                    )
                  "
                  *ngFor="let diagnostic of differentialDiagnosisListFiltered"
                  [value]="diagnostic"
                  >{{ diagnostic.title }}</mat-option
                >
              </mat-autocomplete>
            </mat-form-field>
            <div class="card-container">
              <div
                class="card"
                *ngFor="let card of differentialDiagnosisListSelected"
              >
                <span>
                  {{ transformTitle(card?.title ? card?.title : card?.cie11) }}
                </span>
                <mat-icon
                  *ngIf="!currentVisitDetails.isFollowUp"
                  (click)="removeDiffDiagnosis(card._id)"
                  >close</mat-icon
                >
              </div>
            </div>

            <!--     <app-text-area
              controller="differentialDiagnosisCie11"
              [data]="differentialDiagnosisCie11"
              label="{{ 'Differential Diagnosis' | translate }}"
              (change)="setDifferentialDiagnosis($event)"
              [edit]="isRegularFormEnabled"
            ></app-text-area> -->

            <!-- <mat-form-field class="my-form-field" style="width: 91%">
              <input
                matInput
                placeholder="{{ 'Diagnosis' | translate }}"
                formControlName="diagnosis"
              />
            </mat-form-field> -->
            <!--   <app-text-area
              controller="diagnosisCie11"
              [data]="diagnosisCie11"
              label="{{ 'Diagnosis' | translate }}"
              (change)="setDiagnosis($event)"
              [edit]="isRegularFormEnabled"
              [valid]="isDiagnosisValid"
            ></app-text-area> -->

            <mat-form-field style="width: 100%" class="my-form-field">
              <mat-label>{{ "Diagnosis" | translate }}</mat-label>
              <input
                type="text"
                aria-label="string"
                matInput
                formControlName="diagnosisCie11"
                (ngModelChange)="onChangeDiagnosisCie11($event)"
                [matAutocomplete]="diagAuto"
              />
              <mat-autocomplete #diagAuto="matAutocomplete">
                <mat-option
                  (click)="
                    addDiagnosis(
                      diagnostic,
                      diagnosticListSelected,
                      'diagnosisCie11'
                    )
                  "
                  *ngFor="let diagnostic of diagnosticListFiltered"
                  [value]="diagnostic"
                  >{{ diagnostic.title }}</mat-option
                >
              </mat-autocomplete>
            </mat-form-field>
            <div class="card-container">
              <div class="card" *ngFor="let card of diagnosticListSelected">
                <span>
                  {{
                    transformTitle(card?.title ? card?.title : card?.cie11)
                  }}</span
                >
                <mat-icon
                  *ngIf="!currentVisitDetails.isFollowUp"
                  (click)="removeDiagnosis(card._id)"
                >
                  close</mat-icon
                >
              </div>
            </div>
            <!-- <mat-form-field class="my-form-field" style="width: 91%">
              <input
                matInput
                placeholder="{{ 'Plan' | translate }}"
                formControlName="plan"
              />
              <mat-error *ngIf="form.get('plan').hasError('required')">
                {{ "Plan is Required." | translate }}
              </mat-error>
            </mat-form-field> -->
            <app-text-area
              class="margin-top25"
              controller="plan"
              [data]="plan"
              label="{{ 'Plan' | translate }}"
              (change)="setPlan($event)"
              [edit]="isRegularFormEnabled"
              [valid]="isPlanValid"
              [style]="'normal'"
            ></app-text-area>
            <!-- <mat-form-field class="my-form-field" style="width: 91%">
              <input
                matInput
                placeholder="{{ 'Doctor Notes' | translate }}"
                formControlName="doctorNotes"
              />
            </mat-form-field> -->

            <form formGroupName="closeAttentionForm">
              <div
                class="end-of-medical-care"
                *ngIf="
                  !followCallingUp &&
                  !this.singPatientRecord?.consultation?.isFollowUp
                "
              >
                <div class="completed-medical-care switch-config-button">
                  <span class="margin-left5">{{
                    "Closing this attention" | translate
                  }}</span>
                  <mat-slide-toggle
                    #slideCloseAttention
                    formControlName="closeAttention"
                    (change)="closeAttentionChange($event)"
                    id="slide-question"
                    class="test-call-toggle"
                  >
                    <span
                      *ngIf="closeAttentionForm.enabled"
                      class="slide-question-label"
                      [ngClass]="{ checked: slideCloseAttention.checked }"
                    >
                      {{
                        (slideCloseAttention.checked ? "Yes" : "No") | translate
                      }}
                    </span>
                  </mat-slide-toggle>
                </div>

                <div *ngIf="slideCloseAttention.checked" class="closure-reason">
                  <div class="left-side">
                    <mat-form-field class="select-reason">
                      <mat-label
                        >{{ "Query closure reason" | translate }} *</mat-label
                      >
                      <mat-select
                        formControlName="closureReason"
                        class="closure-reason-select"
                        (selectionChange)="closureReasonChange($event)"
                      >
                        <mat-option
                          *ngFor="let closureReason of closureReasons"
                          [value]="closureReason._id"
                          class="multiline-mat-option reason-option"
                        >
                          {{ closureReason.closureReason[currentLanguage] }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="right-side" *ngIf="isOtherClosureReason">
                    <mat-form-field>
                      <mat-label>{{
                        "Reason for closure" | translate
                      }}</mat-label>
                      <input
                        matInput
                        formControlName="otherClosureReason"
                        maxlength="35"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </form>

            <app-text-area
              controller="doctorNotes"
              [data]="doctorNotes"
              label="{{ 'Doctor Notes' | translate }}"
              (change)="setDoctorNotes($event)"
              [edit]="isRegularFormEnabled"
              [style]="'normal'"
            ></app-text-area>
          </mat-card>
        </div>

        <mat-card class="paraclincal-prescription-container">
          <h6 class="subheading">
            {{ "Paraclinical Prescription" | translate }}
          </h6>

          <div formArrayName="paraclinicalPrescription" *ngIf="form">
            <div
              *ngFor="
                let P of form['controls'].paraclinicalPrescription['controls'];
                let ip = index
              "
            >
              <div formGroupName="{{ ip }}" class="paraclinical-grid">
                <mat-form-field style="width: 20%" class="my-form-field">
                  <mat-label>{{ "Title" | translate }}</mat-label>
                  <input matInput formControlName="title" />
                </mat-form-field>
                
                <mat-form-field class="my-form-field" style="width: 65%">
                  <mat-label>{{ "Description" | translate }}</mat-label>
                  <input matInput formControlName="description" />
                </mat-form-field>
                
                <span
                  class="circle"
                  *ngIf="
                    isRegularFormEnabled &&
                    form['controls'].paraclinicalPrescription['controls']
                      .length -
                      1 ==
                      ip
                  "
                  (click)="addParaClinicalPrescription()"
                >
                  <mat-icon
                    ><img src="../../../assets/images/add.svg"
                  /></mat-icon>
                  <!-- <img src="../../../assets/images/addnew.svg" /> -->
                  <!-- <span style="padding-top: 2%; padding-left: 5px;">{{ "Add New" | translate}}</span> -->
                </span>
                <span
                  class="circle"
                  *ngIf="
                    isRegularFormEnabled &&
                    form['controls'].paraclinicalPrescription['controls']
                      .length > 1
                  "
                  (click)="removeParaClinicalPrescription(ip)"
                >
                  <mat-icon>
                    <mat-icon
                      ><img
                        style="margin-left: 5px"
                        src="../../../assets/images/delete-all.svg"
                    /></mat-icon>
                  </mat-icon>
                  <!-- <img src="../../../assets/images/deletes.svg" /> -->
                </span>
              </div>
            </div>
          </div>
        </mat-card>

        <mat-card class="medical-prescription-container">
          <!-- X -->
          <div formArrayName="medicalPrescription" *ngIf="form">
            <div
              *ngFor="
                let X of form['controls'].medicalPrescription['controls'];
                let ix = index
              "
            >
              <mat-card class="primary-container">
                <h6 class="subheading">
                  {{ "Medical Prescription" | translate }}
                </h6>
                <div formGroupName="{{ ix }}" class="medical-prescription-grid">
                  <!-- MEDICAMENTO -->
                  <mat-form-field class="my-form-field" style="width: 50%">
                    <mat-label
                      >{{ "Medication Name" | translate }}
                      {{ ix + 1 }}</mat-label
                    >
                    <input
                      matInput
                      formControlName="name"
                      (input)="getNameUpdate($event, ix, false)"
                    />
                  </mat-form-field>
                  
                  <!-- DOSIS -->
                  <mat-form-field class="my-form-field" style="width: 100px">
                    <mat-label>{{ "Dosage" | translate }}</mat-label>
                    <input matInput formControlName="dosage" />
                  </mat-form-field>
                  
                  <!-- UNIDADES -->
                  <mat-form-field>
                    <mat-label>
                      {{ "international unit (s)" | translate}}
                    </mat-label>
                    <mat-select formControlName="unit">
                      @for( u of Units; track u._id ) {
                        <mat-option [value]="u._id">
                          {{ u.unit[currentLanguage]}}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field style="width: 150px" class="my-form-field">
                    <mat-label>{{ "Route (s)" | translate }}</mat-label>

                    <mat-select formControlName="routeOfAdministration">
                      <mat-option
                        *ngFor="let admin of adminstrations"
                        [value]="admin._id"
                      >
                        {{
                          admin.routeOfAdministration[currentLanguage]
                        }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <!-- <p>{{ formErrors.medicalPrescription[ix].routeOfAdministration }}</p> -->
                  <mat-form-field class="my-form-field" style="width: 100px">
                    <mat-label>{{ "Frequency" | translate }} asdasd</mat-label>
                    <input
                      type="number"
                      matInput
                      min="0"
                      formControlName="frequency"
                      (keypress)="_keyPress($event)"
                    />
                  </mat-form-field>
                  <!-- <p>{{ formErrors.medicalPrescription[ix].frequency }}</p> -->
                  <mat-form-field style="width: 100px" class="my-form-field">
                    <mat-label>{{ "units" | translate }}</mat-label>
                    <mat-select formControlName="frequencyTime">
                      <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                        t.time[currentLanguage]
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- <p>{{ formErrors.medicalPrescription[ix].frequencyTime }}</p> -->
                  <mat-form-field class="my-form-field" style="width: 100px">
                    <mat-label>{{ "Duration" | translate }}</mat-label>
                    <input
                      type="number"
                      matInput
                      min="0"
                      formControlName="duration"
                      (keypress)="_keyPress($event)"
                    />
                  </mat-form-field>
                  <!-- <p>{{ formErrors.medicalPrescription[ix].duration }}</p> -->
                  <mat-form-field style="width: 150px" class="my-form-field">
                    <mat-label>{{ "units" | translate }}</mat-label>
                    <mat-select formControlName="durationTime">
                      <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                        t.time[currentLanguage]
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- <p>{{ formErrors.medicalPrescription[ix].durationTime }}</p> -->
                  <!-- <input type="text" formControlName="notes"> -->
                  <span
                    class="circle"
                    *ngIf="
                      X['controls'].alternative['controls'].length - 1 == -1 &&
                      isRegularFormEnabled
                    "
                    (click)="addY(ix, false)"
                  >
                    <mat-icon
                      ><img src="../../../assets/images/add.svg"
                    /></mat-icon>
                  </span>
                  <span
                    class="circle"
                    *ngIf="
                      isRegularFormEnabled &&
                      form['controls'].medicalPrescription['controls'].length >
                        1
                    "
                    (click)="removeX(ix, false)"
                  >
                    <mat-icon>
                      <mat-icon
                        ><img
                          style="margin-left: 5px"
                          src="../../../assets/images/delete-all.svg"
                      /></mat-icon>
                    </mat-icon>
                  </span>

                  <mat-form-field style="width: 100%" appearance="outline">
                    <textarea
                      style="resize: none"
                      class="notes"
                      rows="5"
                      cols="117"
                      placeholder="{{ formControlPlaceholder | translate }}"
                      formControlName="notes"
                      matInput
                    ></textarea>
                  </mat-form-field>
                  <!-- <div> -->
                  <!-- <textarea
                    class="notes"
                    rows="5"
                    cols="117"
                    placeholder="Notes..."
                    formControlName="notes"
                  ></textarea> -->
                  <!-- </div> -->
                  <!-- </mat-form-field> -->

                  <!-- Y -->
                  <div formArrayName="alternative">
                    <div
                      *ngFor="
                        let Y of X['controls'].alternative['controls'];
                        let iy = index
                      "
                    >
                      <div
                        formGroupName="{{ iy }}"
                        class="medical-prescription-grid"
                      >
                        <mat-form-field
                          class="my-form-field"
                          style="width: 50%"
                        >
                          <mat-label
                            >{{ "Alternative" | translate }} -
                            {{ iy + 1 }}</mat-label
                          >
                          <input
                            matInput
                            formControlName="name"
                            (change)="
                              getAlternateNameUpdate($event, ix, iy, false)
                            "
                          />
                        </mat-form-field>
                        <!-- <p> {{ formErrors.medicalPrescription[ix].alternative[iy].name }}</p> -->
                        <mat-form-field
                          class="my-form-field"
                          style="width: 100px"
                        >
                          <mat-label>{{ "Dosage" | translate }}</mat-label>
                          <input matInput formControlName="dosage" />
                        </mat-form-field>
                        <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].dosage }}</p> -->
                        <mat-form-field
                          style="width: 100px"
                          class="my-form-field"
                        >
                          <mat-label>{{
                            "international unit (s)" | translate
                          }}</mat-label>

                          <mat-select formControlName="unit">
                            <mat-option
                              *ngFor="let u of Units"
                              [value]="u._id"
                              >{{ u.unit[currentLanguage] }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].unit }}</p> -->
                        <mat-form-field
                          style="width: 100px"
                          class="my-form-field"
                        >
                          <mat-label>{{ "Route (s)" | translate }}</mat-label>

                          <mat-select formControlName="routeOfAdministration">
                            <mat-option
                              *ngFor="let admin of adminstrations"
                              [value]="admin._id"
                            >
                              {{
                                admin.routeOfAdministration[currentLanguage]
                              }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].routeOfAdministration }}</p> -->
                        <mat-form-field
                          class="my-form-field"
                          style="width: 100px"
                        >
                          <mat-label>{{ "Frequency" | translate }}</mat-label>
                          <input
                            type="number"
                            matInput
                            min="0"
                            formControlName="frequency"
                            (keypress)="_keyPress($event)"
                          />
                        </mat-form-field>
                        <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].frequency }}</p> -->
                        <mat-form-field
                          style="width: 100px"
                          class="my-form-field"
                        >
                          <mat-label>{{ "units" | translate }}</mat-label>
                          <mat-select formControlName="frequencyTime">
                            <mat-option
                              *ngFor="let t of tablete"
                              [value]="t._id"
                              >{{ t.time[currentLanguage] }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].frequencyTime }}</p> -->
                        <mat-form-field
                          class="my-form-field"
                          style="width: 100px"
                        >
                          <mat-label>{{ "Duration" | translate }}</mat-label>
                          <input
                            type="number"
                            matInput
                            min="0"
                            placeholder=""
                            formControlName="duration"
                            (keypress)="_keyPress($event)"
                          />
                        </mat-form-field>
                        <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].duration }}</p> -->
                        <mat-form-field
                          style="width: 100px"
                          class="my-form-field"
                        >
                          <mat-label>{{ "units" | translate }}</mat-label>
                          <mat-select formControlName="durationTime">
                            <mat-option
                              *ngFor="let t of tablete"
                              [value]="t._id"
                              >{{ t.time[currentLanguage] }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <!-- <div (click)="addY(ix)">
                    Add alternative
                  </div> -->
                        <span
                          class="circle"
                          *ngIf="
                            isRegularFormEnabled &&
                            X['controls'].alternative['controls'].length - 1 ==
                              iy
                          "
                          (click)="addY(ix, false)"
                        >
                          <mat-icon
                            ><img src="../../../assets/images/add.svg"
                          /></mat-icon>
                        </span>
                        <span
                          *ngIf="isRegularFormEnabled"
                          class="circle"
                          (click)="removeY(ix, iy, false)"
                        >
                          <mat-icon>
                            <mat-icon
                              ><img
                                style="margin-left: 5px"
                                src="../../../assets/images/delete-all.svg"
                            /></mat-icon>
                          </mat-icon>
                        </span>
                        <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].durationTime }}</p> -->
                        <!-- <mat-form-field
                          style="width: 100%;"
                          appearance="outline"
                        > -->
                        <!-- <textarea
                            matInput
                            class="notes"
                            rows="5"
                            cols="117"
                            placeholder="Notes..."
                            formControlName="notes"
                          ></textarea> -->
                        <!-- <div>
                          <textarea
                            class="notes"
                            rows="5"
                            cols="117"
                            placeholder="Notes..."
                            formControlName="notes"
                          ></textarea>
                        </div> -->
                        <!-- </mat-form-field> -->
                        <mat-form-field
                          style="width: 100%"
                          appearance="outline"
                        >
                          <textarea
                            style="resize: none"
                            class="notes"
                            rows="5"
                            cols="117"
                            placeholder="{{
                              formControlPlaceholder | translate
                            }}"
                            formControlName="notes"
                            matInput
                          ></textarea>
                        </mat-form-field>
                        <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].notes }}</p> -->
                      </div>
                    </div>
                  </div>
                  <div
                    style="text-align: center"
                    *ngIf="
                      form['controls'].medicalPrescription['controls'].length -
                        1 ==
                      ix
                    "
                  >
                    <span
                      class="circle"
                      style="display: inline-flex"
                      (click)="addX(false)"
                      *ngIf="isRegularFormEnabled"
                    >
                      <mat-icon
                        ><img src="../../../assets/images/add.svg"
                      /></mat-icon>
                      <span style="padding-left: 10px; padding-top: 2px">{{
                        "Add Next" | translate
                      }}</span>
                    </span>
                  </div>
                  <!-- Y End-->
                </div>
              </mat-card>
            </div>
          </div>
          <!-- X End -->
        </mat-card>

        @if( haveFollowUp1 ) {
          <div formGroupName="mpFollowUpForm1">
            <mat-card class="cards">
              <h6 class="subheading">
                {{ "Follow up Information" | translate }} 1
              </h6>
              <app-text-area
                controller="fSubjective"
                [data]="previousFollowUp1.subjective"
                label="{{ 'Subjective' | translate }}"
                [edit]="false"
              ></app-text-area>
              <app-text-area
                controller="fObjective"
                [data]="previousFollowUp1.objective"
                label="{{ 'Objective' | translate }}"
                [edit]="false"
              ></app-text-area>
              <!--   <app-text-area
                controller="fAssesment"
                [data]="previousFollowUp1.assesment"
                label=""
                [edit]="false"
              ></app-text-area> -->
              <div>
                <label style="color: #92a0a5">{{
                  "Assessment" | translate
                }}</label>
                <div
                  style="margin-top: 10px"
                  *ngIf="previousFollowUp1.assesmentCie11.length > 0"
                  class="card-container"
                >
                  <div
                    class="card"
                    *ngFor="let card of previousFollowUp1.assesmentCie11"
                  >
                    <span>
                      {{
                        transformTitle(card?.title ? card?.title : card?.cie11)
                      }}</span
                    >
                  </div>
                </div>
                <div
                  style="margin-top: 10px; margin-bottom: 10px"
                  *ngIf="previousFollowUp1.assesmentCie11.length < 1"
                  [innerHTML]="previousFollowUp1.assesment"
                ></div>
              </div>
              <app-text-area
                controller="fPlan"
                [data]="previousFollowUp1.plan"
                label="{{ 'Plan' | translate }}"
                [edit]="false"
              ></app-text-area>
  
              <form [formGroup]="mpFollowUpForm1">
                <div formArrayName="medicalPrescription">
                  <div
                    *ngFor="
                      let X of mpFollowUpForm1['controls'].medicalPrescription[
                        'controls'
                      ];
                      let ix = index
                    "
                  >
                    <h6 class="subheading">
                      {{ "Medical Prescription" | translate }}
                    </h6>
                    <div
                      formGroupName="{{ ix }}"
                      class="medical-prescription-grid"
                    >
                      <mat-form-field class="my-form-field" style="width: 50%">
                        <mat-label
                          >{{ "Medication Name" | translate }}
                          {{ ix + 1 }}</mat-label
                        >
                        <input matInput placeholder="" formControlName="name" />
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Dosage" | translate }}</mat-label>
                        <input matInput placeholder="" formControlName="dosage" />
                      </mat-form-field>
                      <mat-form-field style="width: 150px" class="my-form-field">
                        <mat-label>{{
                          "international unit (s)" | translate
                        }}</mat-label>
                        <mat-select formControlName="unit">
                          <mat-option *ngFor="let u of Units" [value]="u._id">{{
                            u.unit[currentLanguage]
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 150px" class="my-form-field">
                        <mat-label>{{ "Route (s)" | translate }}</mat-label>
                        <mat-select formControlName="routeOfAdministration">
                          <mat-option
                            *ngFor="let admin of adminstrations"
                            [value]="admin._id"
                          >
                            {{ admin.routeOfAdministration[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Frequency" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="frequency"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="frequencyTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                            t.time[currentLanguage]
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Duration" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="duration"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 150px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="durationTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">
                            {{ t.time[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
  
                      <mat-form-field style="width: 100%" appearance="outline">
                        <textarea
                          style="resize: none"
                          class="notes"
                          rows="5"
                          cols="117"
                          placeholder="{{ formControlPlaceholder | translate }}"
                          formControlName="notes"
                          matInput
                        ></textarea>
                      </mat-form-field>
                      <div formArrayName="alternative">
                        <div
                          *ngFor="
                            let Y of X['controls'].alternative['controls'];
                            let iy = index
                          "
                        >
                          <div
                            formGroupName="{{ iy }}"
                            class="medical-prescription-grid"
                          >
                            <mat-form-field
                              class="my-form-field"
                              style="width: 50%"
                            >
                              <mat-label
                                >{{ "Alternative" | translate }} -
                                {{ iy + 1 }}</mat-label
                              >
                              <input
                                matInput
                                placeholder=""
                                formControlName="name"
                              />
                            </mat-form-field>
                            <mat-form-field
                              class="my-form-field"
                              style="width: 100px"
                            >
                              <mat-label>{{ "Dosage" | translate }}</mat-label>
                              <input
                                matInput
                                placeholder=""
                                formControlName="dosage"
                              />
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{
                                "international unit (s)" | translate
                              }}</mat-label>
                              <mat-select formControlName="unit">
                                <mat-option
                                  *ngFor="let u of Units"
                                  [value]="u._id"
                                >
                                  {{ u.unit[currentLanguage] }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{ "Route (s)" | translate }}</mat-label>
                              <mat-select formControlName="routeOfAdministration">
                                <mat-option
                                  *ngFor="let admin of adminstrations"
                                  [value]="admin._id"
                                >
                                  {{
                                    admin.routeOfAdministration[currentLanguage]
                                  }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              class="my-form-field"
                              style="width: 100px"
                            >
                              <input
                                type="number"
                                matInput
                                min="0"
                                placeholder="{{ 'Frequency' | translate }}"
                                formControlName="frequency"
                              />
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{ "units" | translate }}</mat-label>
                              <mat-select formControlName="frequencyTime">
                                <mat-option
                                  *ngFor="let t of tablete"
                                  [value]="t._id"
                                >
                                  {{ t.time[currentLanguage] }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              class="my-form-field"
                              style="width: 100px"
                            >
                              <mat-label>{{ "Duration" | translate }}</mat-label>
                              <input
                                type="number"
                                matInput
                                min="0"
                                placeholder=""
                                formControlName="duration"
                              />
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{ "units" | translate }}</mat-label>
                              <mat-select formControlName="durationTime">
                                <mat-option
                                  *ngFor="let t of tablete"
                                  [value]="t._id"
                                >
                                  {{ t.time[currentLanguage] }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100%"
                              appearance="outline"
                            >
                              <textarea
                                style="resize: none"
                                class="notes"
                                rows="5"
                                cols="117"
                                placeholder="{{
                                  formControlPlaceholder | translate
                                }}"
                                formControlName="notes"
                                matInput
                              ></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-card>
          </div>
        }

        @if (haveFollowUp2  ) {
          <div formGroupName="mpFollowUpForm2">
            <mat-card class="cards">
              <h6 class="subheading">
                {{ "Follow up Information" | translate }} 2
              </h6>
              <app-text-area
                controller="fSubjective"
                [data]="previousFollowUp2.subjective"
                label="{{ 'Subjective' | translate }}"
                [edit]="false"
              ></app-text-area>
              <app-text-area
                controller="fObjective"
                [data]="previousFollowUp2.objective"
                label="{{ 'Objective' | translate }}"
                [edit]="false"
              ></app-text-area>
              <!--       <app-text-area
                controller="fAssesment"
                [data]="previousFollowUp2.assesment"
                label="{{ 'Assessment' | translate }}"
                [edit]="false"
              ></app-text-area> -->
              <!--   <div>
                <label  style="color: #92a0a5;" >{{ 'Assessment' | translate }}</label>
                <div  style="margin-top: 10px;" *ngIf="previousFollowUp1.assesmentCie11.length > 0"  class="card-container">
                  <div class="card"  *ngFor="let card of previousFollowUp1.assesmentCie11">
                    <span> {{ transformTitle(card?.title ? card?.title : card?.cie11)}}</span>
                  </div>
                </div>
                <div style="margin-top: 10px;margin-bottom: 10px;"
                *ngIf="previousFollowUp1.assesmentCie11.length < 1"
                [innerHTML]="previousFollowUp1.assesment">  </div>
              </div> -->
              <div>
                <label style="color: #92a0a5">{{
                  "Assessment" | translate
                }}</label>
                <div
                  style="margin-top: 10px"
                  *ngIf="previousFollowUp2.assesmentCie11.length > 0"
                  class="card-container"
                >
                  <div
                    class="card"
                    *ngFor="let card of previousFollowUp2.assesmentCie11"
                  >
                    <span>
                      {{
                        transformTitle(card?.title ? card?.title : card?.cie11)
                      }}</span
                    >
                  </div>
                </div>
                <div
                  style="margin-top: 10px; margin-bottom: 10px"
                  *ngIf="previousFollowUp2.assesmentCie11.length < 1"
                  [innerHTML]="previousFollowUp1.assesment"
                ></div>
              </div>
              <app-text-area
                controller="fPlan"
                [data]="previousFollowUp2.plan"
                label="{{ 'Plan' | translate }}"
                [edit]="false"
              ></app-text-area>
  
              <form [formGroup]="mpFollowUpForm2">
                <div formArrayName="medicalPrescription">
                  <div
                    *ngFor="
                      let X of mpFollowUpForm2['controls'].medicalPrescription[
                        'controls'
                      ];
                      let ix = index
                    "
                  >
                    <h6 class="subheading">
                      {{ "Medical Prescription" | translate }}
                    </h6>
                    <div
                      formGroupName="{{ ix }}"
                      class="medical-prescription-grid"
                    >
                      <mat-form-field class="my-form-field" style="width: 50%">
                        <mat-label
                          >{{ "Medication Name" | translate }}
                          {{ ix + 1 }}</mat-label
                        >
                        <input matInput placeholder="" formControlName="name" />
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Dosage" | translate }}</mat-label>
                        <input matInput placeholder="" formControlName="dosage" />
                      </mat-form-field>
                      <mat-form-field style="width: 150px" class="my-form-field">
                        <mat-label>{{
                          "international unit (s)" | translate
                        }}</mat-label>
                        <mat-select formControlName="unit">
                          <mat-option *ngFor="let u of Units" [value]="u._id">{{
                            u.unit[currentLanguage]
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 150px" class="my-form-field">
                        <mat-label>{{ "Route (s)" | translate }}</mat-label>
                        <mat-select formControlName="routeOfAdministration">
                          <mat-option
                            *ngFor="let admin of adminstrations"
                            [value]="admin._id"
                          >
                            {{ admin.routeOfAdministration[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Frequency" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="frequency"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="frequencyTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                            t.time[currentLanguage]
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Duration" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="duration"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 150px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="durationTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">
                            {{ t.time[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
  
                      <mat-form-field style="width: 100%" appearance="outline">
                        <textarea
                          style="resize: none"
                          class="notes"
                          rows="5"
                          cols="117"
                          placeholder="{{ formControlPlaceholder | translate }}"
                          formControlName="notes"
                          matInput
                        ></textarea>
                      </mat-form-field>
                      <div formArrayName="alternative">
                        <div
                          *ngFor="
                            let Y of X['controls'].alternative['controls'];
                            let iy = index
                          "
                        >
                          <div
                            formGroupName="{{ iy }}"
                            class="medical-prescription-grid"
                          >
                            <mat-form-field
                              class="my-form-field"
                              style="width: 50%"
                            >
                              <mat-label
                                >{{ "Alternative" | translate }} -
                                {{ iy + 1 }}</mat-label
                              >
                              <input
                                matInput
                                placeholder=""
                                formControlName="name"
                              />
                            </mat-form-field>
                            <mat-form-field
                              class="my-form-field"
                              style="width: 100px"
                            >
                              <mat-label>{{ "Dosage" | translate }}</mat-label>
                              <input
                                matInput
                                placeholder=""
                                formControlName="dosage"
                              />
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{
                                "international unit (s)" | translate
                              }}</mat-label>
                              <mat-select formControlName="unit">
                                <mat-option
                                  *ngFor="let u of Units"
                                  [value]="u._id"
                                >
                                  {{ u.unit[currentLanguage] }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{ "Route (s)" | translate }}</mat-label>
                              <mat-select formControlName="routeOfAdministration">
                                <mat-option
                                  *ngFor="let admin of adminstrations"
                                  [value]="admin._id"
                                >
                                  {{
                                    admin.routeOfAdministration[currentLanguage]
                                  }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              class="my-form-field"
                              style="width: 100px"
                            >
                              <mat-label>{{ "Frequency" | translate }}</mat-label>
                              <input
                                type="number"
                                matInput
                                min="0"
                                placeholder=""
                                formControlName="frequency"
                              />
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{ "units" | translate }}</mat-label>
                              <mat-select formControlName="frequencyTime">
                                <mat-option
                                  *ngFor="let t of tablete"
                                  [value]="t._id"
                                >
                                  {{ t.time[currentLanguage] }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              class="my-form-field"
                              style="width: 100px"
                            >
                              <mat-label>{{ "Duration" | translate }}</mat-label>
                              <input
                                type="number"
                                matInput
                                min="0"
                                placeholder=""
                                formControlName="duration"
                              />
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{ "units" | translate }}</mat-label>
                              <mat-select formControlName="durationTime">
                                <mat-option
                                  *ngFor="let t of tablete"
                                  [value]="t._id"
                                >
                                  {{ t.time[currentLanguage] }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100%"
                              appearance="outline"
                            >
                              <textarea
                                style="resize: none"
                                class="notes"
                                rows="5"
                                cols="117"
                                placeholder="{{
                                  formControlPlaceholder | translate
                                }}"
                                formControlName="notes"
                                matInput
                              ></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-card>
          </div>
        }

        @if ( singPatientRecord?.consultation?.isFollowUp ) {

          <div formGroupName="followUpForm">
            <mat-card class="cards">
              <h6 class="subheading">
                {{ "Follow up Information" | translate }}
                {{ haveFollowUp2 ? "3" : haveFollowUp1 ? "2" : "1" }}
              </h6>
              <app-text-area
                controller="fSubjective"
                [data]="followUpCall.subjective"
                label="{{ 'Subjective' | translate }}"
                (change)="updateFollowUp('subjective', $event)"
                [edit]="isFollowUpEditEnabled"
              ></app-text-area>
              <app-text-area
                controller="fObjective"
                [data]="followUpCall.objective"
                label="{{ 'Objective' | translate }}"
                (change)="updateFollowUp('objective', $event)"
                [edit]="isFollowUpEditEnabled"
              ></app-text-area>
              <!--      <app-text-area
                controller="fAssesment"
                [data]="followUpCall.assesment"
                label="{{ 'Assessment' | translate }}"
                (change)="updateFollowUp('assesment', $event)"
                [edit]="isFollowUpEditEnabled"
              ></app-text-area> -->
              <mat-form-field style="width: 100%" class="my-form-field">
                <mat-label>{{ "Assessment" | translate }}</mat-label>
                <input
                  type="text"
                  id="assessmentID"
                  matInput
                  [(ngModel)]="assessmentValue"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="onChange($event)"
                  [matAutocomplete]="diffAssessment"
                />
  
                <mat-autocomplete #diffAssessment="matAutocomplete">
                  <mat-option
                    (click)="addAssessment(diagnostic, assessmentListSelected)"
                    *ngFor="let diagnostic of assessmentListFiltered"
                    [value]="diagnostic"
                    >{{ diagnostic.title }}</mat-option
                  >
                </mat-autocomplete>
              </mat-form-field>
              <div class="card-container">
                <div class="card" *ngFor="let card of assessmentListSelected">
                  <span>
                    {{
                      transformTitle(card?.title ? card?.title : card?.cie11)
                    }}</span
                  >
                  <mat-icon (click)="removeAssessment(card._id)"> close</mat-icon>
                </div>
              </div>
              <app-text-area
                controller="fPlan"
                [data]="followUpCall.plan"
                label="{{ 'Plan' | translate }}"
                (change)="updateFollowUp('plan', $event)"
                [edit]="isFollowUpEditEnabled"
              ></app-text-area>
  
              <form formGroupName="closeAttentionForm" *ngIf="!followCallingUp">
                <div class="end-of-medical-care">
                  <div
                    class="completed-medical-care"
                    class="switch-config-button"
                  >
                    <span style="margin-right: 20px">{{
                      "Closing this attention" | translate
                    }}</span>
                    <mat-slide-toggle
                      #slideCloseAttention
                      class="test-call-toggle"
                      formControlName="closeAttention"
                      (change)="closeAttentionChange($event)"
                      id="slide-question"
                    >
                      <span
                        *ngIf="closeAttentionForm.enabled"
                        class="slide-question-label"
                        [ngClass]="{ checked: slideCloseAttention.checked }"
                      >
                        {{
                          (slideCloseAttention.checked ? "Yes" : "No") | translate
                        }}
                      </span>
                    </mat-slide-toggle>
                  </div>
  
                  <div *ngIf="slideCloseAttention.checked" class="closure-reason">
                    <div class="left-side">
                      <mat-form-field class="select-reason">
                        <mat-label
                          >{{ "Query closure reason" | translate }} *</mat-label
                        >
                        <mat-select
                          formControlName="closureReason"
                          class="closure-reason-select"
                          (selectionChange)="closureReasonChange($event)"
                        >
                          <mat-option
                            *ngFor="let closureReason of closureReasons"
                            [value]="closureReason._id"
                            class="multiline-mat-option reason-option"
                          >
                            {{ closureReason.closureReason[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
  
                    <div class="right-side" *ngIf="isOtherClosureReason">
                      <mat-form-field>
                        <mat-label>{{
                          "Reason for closure" | translate
                        }}</mat-label>
                        <input
                          matInput
                          formControlName="otherClosureReason"
                          maxlength="35"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </form>
  
              <form [formGroup]="mpFollowUpForm">
                <div formArrayName="medicalPrescription">
                  <div
                    *ngFor="
                      let X of mpFollowUpForm['controls'].medicalPrescription[
                        'controls'
                      ];
                      let ix = index
                    "
                  >
                    <h6 class="subheading">
                      {{ "Medical Prescription" | translate }}
                    </h6>
                    <div
                      formGroupName="{{ ix }}"
                      class="medical-prescription-grid"
                    >
                      <mat-form-field class="my-form-field" style="width: 50%">
                        <mat-label
                          >{{ "Medication Name" | translate }}
                          {{ ix + 1 }}</mat-label
                        >
                        <input
                          matInput
                          placeholder=""
                          formControlName="name"
                          (change)="getNameUpdate($event, ix, true)"
                        />
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Dosage" | translate }}</mat-label>
                        <input matInput placeholder="" formControlName="dosage" />
                      </mat-form-field>
                      <mat-form-field style="width: 150px" class="my-form-field">
                        <mat-label>{{
                          "international unit (s)" | translate
                        }}</mat-label>
                        <mat-select formControlName="unit">
                          <mat-option *ngFor="let u of Units" [value]="u._id">{{
                            u.unit[currentLanguage]
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 150px" class="my-form-field">
                        <mat-label>{{ "Route (s)" | translate }}</mat-label>
                        <mat-select formControlName="routeOfAdministration">
                          <mat-option
                            *ngFor="let admin of adminstrations"
                            [value]="admin._id"
                          >
                            {{ admin.routeOfAdministration[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Frequency" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="frequency"
                          (keypress)="_keyPress($event)"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="frequencyTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                            t.time[currentLanguage]
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Duration" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="duration"
                          (keypress)="_keyPress($event)"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 150px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="durationTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">
                            {{ t.time[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <span
                        class="circle"
                        *ngIf="
                          X['controls'].alternative['controls'].length - 1 ==
                            -1 && isFollowUpEditEnabled
                        "
                        (click)="addY(ix, true)"
                      >
                        <mat-icon
                          ><img src="../../../assets/images/add.svg"
                        /></mat-icon>
                      </span>
                      <span
                        class="circle"
                        *ngIf="
                          isFollowUpEditEnabled &&
                          mpFollowUpForm['controls'].medicalPrescription[
                            'controls'
                          ].length > 1
                        "
                        (click)="removeX(ix, true)"
                      >
                        <mat-icon>
                          <mat-icon
                            ><img
                              style="margin-left: 5px"
                              src="../../../assets/images/delete-all.svg"
                          /></mat-icon>
                        </mat-icon>
                      </span>
  
                      <mat-form-field style="width: 100%" appearance="outline">
                        <textarea
                          style="resize: none"
                          class="notes"
                          rows="5"
                          cols="117"
                          placeholder="{{ formControlPlaceholder | translate }}"
                          formControlName="notes"
                          matInput
                        ></textarea>
                      </mat-form-field>
                      <div formArrayName="alternative">
                        <div
                          *ngFor="
                            let Y of X['controls'].alternative['controls'];
                            let iy = index
                          "
                        >
                          <div
                            formGroupName="{{ iy }}"
                            class="medical-prescription-grid"
                          >
                            <mat-form-field
                              class="my-form-field"
                              style="width: 50%"
                            >
                              <mat-label
                                >{{ "Alternative" | translate }} -
                                {{ iy + 1 }}</mat-label
                              >
                              <input
                                matInput
                                placeholder=""
                                formControlName="name"
                                (change)="
                                  getAlternateNameUpdate($event, ix, iy, true)
                                "
                              />
                            </mat-form-field>
                            <mat-form-field
                              class="my-form-field"
                              style="width: 100px"
                            >
                              <mat-label>{{ "Dosage" | translate }}</mat-label>
                              <input
                                matInput
                                placeholder=""
                                formControlName="dosage"
                              />
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{
                                "international unit (s)" | translate
                              }}</mat-label>
                              <mat-select formControlName="unit">
                                <mat-option
                                  *ngFor="let u of Units"
                                  [value]="u._id"
                                >
                                  {{ u.unit[currentLanguage] }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{ "Route (s)" | translate }}</mat-label>
                              <mat-select formControlName="routeOfAdministration">
                                <mat-option
                                  *ngFor="let admin of adminstrations"
                                  [value]="admin._id"
                                >
                                  {{
                                    admin.routeOfAdministration[currentLanguage]
                                  }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              class="my-form-field"
                              style="width: 100px"
                            >
                              <mat-label>{{ "Frequency" | translate }}</mat-label>
                              <input
                                type="number"
                                matInput
                                min="0"
                                placeholder=""
                                formControlName="frequency"
                                (keypress)="_keyPress($event)"
                              />
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{ "units" | translate }}</mat-label>
                              <mat-select formControlName="frequencyTime">
                                <mat-option
                                  *ngFor="let t of tablete"
                                  [value]="t._id"
                                >
                                  {{ t.time[currentLanguage] }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field
                              class="my-form-field"
                              style="width: 100px"
                            >
                              <mat-label>{{ "Duration" | translate }}</mat-label>
                              <input
                                type="number"
                                matInput
                                min="0"
                                placeholder=""
                                formControlName="duration"
                                (keypress)="_keyPress($event)"
                              />
                            </mat-form-field>
                            <mat-form-field
                              style="width: 100px"
                              class="my-form-field"
                            >
                              <mat-label>{{ "units" | translate }}</mat-label>
                              <mat-select formControlName="durationTime">
                                <mat-option
                                  *ngFor="let t of tablete"
                                  [value]="t._id"
                                >
                                  {{ t.time[currentLanguage] }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <span
                              class="circle"
                              *ngIf="
                                isFollowUpEditEnabled &&
                                X['controls'].alternative['controls'].length -
                                  1 ==
                                  iy
                              "
                              (click)="addY(ix, true)"
                            >
                              <mat-icon
                                ><img src="../../../assets/images/add.svg"
                              /></mat-icon>
                            </span>
                            <span
                              *ngIf="isFollowUpEditEnabled"
                              class="circle"
                              (click)="removeY(ix, iy, true)"
                            >
                              <mat-icon>
                                <mat-icon>
                                  <img
                                    style="margin-left: 5px"
                                    src="../../../assets/images/delete-all.svg"
                                  />
                                </mat-icon>
                              </mat-icon>
                            </span>
                            <mat-form-field
                              style="width: 100%"
                              appearance="outline"
                            >
                              <textarea
                                style="resize: none"
                                class="notes"
                                rows="5"
                                cols="117"
                                placeholder="{{
                                  formControlPlaceholder | translate
                                }}"
                                formControlName="notes"
                                matInput
                              ></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div
                        style="text-align: center"
                        *ngIf="
                          mpFollowUpForm['controls'].medicalPrescription[
                            'controls'
                          ].length -
                            1 ==
                          ix
                        "
                      >
                        <span
                          class="circle"
                          style="display: inline-flex"
                          (click)="addX(true)"
                          *ngIf="isFollowUpEditEnabled"
                        >
                          <mat-icon
                            ><img src="../../../assets/images/add.svg"
                          /></mat-icon>
                          <span style="padding-left: 10px; padding-top: 2px">{{
                            "Add Next" | translate
                          }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </mat-card>
          </div>

        }
        <!-- <input type="submit" #submitBtn value="formSubmit" /> -->
      </form>

      <!-- <h5>Field Values</h5>
  <pre style="font-size: 15px;">{{ form.value | json }}</pre> -->
    </div>
  </div>
</div>
