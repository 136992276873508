import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "../core/authentication.service";
import { HttpErrorResponse } from "@angular/common/http";
import { DataStoreServiceService } from "../dashboard/data-store-service.service";

@Component({
  selector: "app-verify-agent",
  templateUrl: "./verify-agent.component.html",
  styleUrls: ["./verify-agent.component.scss"],
})
export class VerifyAgentComponent implements OnInit {
  verificationCode: string;
  errorMsg: any;
  showError: boolean = false;
  hideForm = false;
  doctorRecord: any;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.verificationCode = params["verificationCode"];
    });
  }

  ngOnInit() {
    if (this.verificationCode != null && this.verificationCode != undefined) {
      this.verifyCode();
    }
  }
  verifyCode() {
    this.authService.codeVerificationAgent(this.verificationCode).subscribe({
      next:(res: any) => {
        /* this.doctorRecord = res["data"];
        localStorage.setItem("registerFirstName", res.data.firstName);
        localStorage.setItem("registerLastName", res.data.lastName);
        localStorage.setItem("registerGender", res.data.gender);
        localStorage.setItem("registerEmail", res.data.email); */

        this.router.navigate(["/create-pwd"]);
      },
      error:(error: HttpErrorResponse) => {
        this.errorMsg = error.error.errors;
        this.hideForm = true;
        this.showError = true;
      },
    });
  }
}
