<div class="wrapper">
  <img src="./../../assets/404.svg" alt="404 Image" />
  <p>La página que estás buscando no se encuentra disponible</p>
  <button
    class="btn-custom"
    [routerLink]="'/'"
    mat-raised-button
    color="primary"
  >
    Ir al home
  </button>
</div>
