import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "../dashboard.service";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { CommonService } from "../common.services";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/shared/shared.service";
import { StatusCall } from "../consults-page/consults-page.component";

export interface PosponeRes {
  data: Data;
}

export interface Data {
  _id:                                 string;
  chiefComplaint:                      DataChiefComplaint;
  informedConsent:                     boolean;
  patient:                             string;
  parent:                              string;
  provider:                            string;
  status:                              number;
  consultationType:                    number;
  appointmentType:                     number;
  appointmentStatus:                   number;
  appointmentRescheduledCount:         number;
  appointmentRescheduledByDoctorCount: number;
  action:                              string[];
  paraclinicalPrescription:            any[];
  medicalPrescription:                 any[];
  providerCall:                        string;
  originCall:                          number;
  patientIdentificationNumber:         string;
  createdAt:                           Date;
  updatedAt:                           Date;
  communicationMode:                   number;
  date:                                Date;
  utcOffset:                           number;
  doctor:                              null;
  document:                            any[];
  hour:                                string;
}

export interface DataChiefComplaint {
  id:             string;
  chiefComplaint: ChiefComplaintChiefComplaint;
}

export interface ChiefComplaintChiefComplaint {
  en: string;
  es: string;
}



@Component({
  selector: "app-postpone-popup",
  templateUrl: "./postpone-popup.component.html",
  styleUrls: ["./postpone-popup.component.scss"],
})
export class PostponePopupComponent implements OnInit {
  consultId: any;
  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private commonServices: CommonService,
    private translationService: TranslateService,
    private sharedService: SharedService,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.consultId = params.id;
    });
  }

  postponeCall() {
    this.dashboardService.postponeCallByDoctor(this.consultId).subscribe({
      next: (res: PosponeRes) => {
        console.log("POSPONIENDO LA LLAMADA");
        console.log(res);
        
        // if ( res.data.status === StatusCall.CANCELLED ) {
        //   this.sharedService.showMessage("La llamada ha sido cancelada exitosamente", "success");
        //   return;
        // }
        
        this.translationService
          .get("Call has been Postponed Successfully")
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });

        this.router.navigate([""]);
      },
      error: (error: HttpErrorResponse) => {
        this.handleError(error);
      },
    });
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
