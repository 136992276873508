<div class="register-container">
  <!-- <app-home-header></app-home-header> -->
  <div class="cus-col">
    <figure class="profile-part">
      <img
        *ngIf="profilePicPath == '' || profilePicPath == undefined"
        mat-card-avatar
        src="../../assets/images/person-icon.png"
        class="img-thumbnail avatar"
      />
      <img
        mat-card-avatar
        *ngIf="profilePicPath != '' && profilePicPath != undefined"
        [src]="profilePicUrl"
        class="img-thumbnail avatar"
      />
      <input
        input
        type="file"
        accept="image/x-png,image/jpeg"
        (change)="updateProfileInfo($event)"
        #profilePic
        style="display: none"
      />

      <button (click)="profilePic.click()">{{ "Edit" | translate }}</button>
      <!-- <div style="position: relative; padding: 0; cursor: pointer;" type="file">
        <img [src]="profilePicUrl" class="img-circle" style="width: 140px; height: 140px;" >
        <span style="position: absolute; color: white; ">Edit</span>
      </div> -->
    </figure>
  </div>
  <div>
    <form (ngSubmit)="editDoctorInfo()" [formGroup]="editProfileForm">
      <div class="grid-container">
        <div class="grid-item">
          <mat-card style="padding-top: 1%">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <p
                style="
                  text-align: initial;
                  color: #46535b !important;
                  width: auto;
                "
              >
                {{ "Personal Information" | translate }}
              </p>
              <span
                (click)="enableProfile()"
                *ngIf="!savebuttonshow"
                style="width: auto"
                ><img
                  src="../../assets/images/editi.svg"
                  style="float: right; cursor: pointer; color: #65a7f6"
              /></span>
            </div>

            <mat-form-field class="my-form-field profile">
              <mat-label>{{ "First Name" | translate }}</mat-label>

              <input
                type="text"
                matInput
                autocomplete="firstName"
                formControlName="firstName"
              />
              <mat-error
                *ngIf="editProfileForm.get('firstName').hasError('required')"
              >
                {{ "First Name is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="editProfileForm.get('firstName').hasError('pattern')"
              >
                {{ "Numbers are not allowed in First Name field" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="my-form-field profile">
              <mat-label>{{ "Last Name" | translate }}</mat-label>
              <input
                type="text"
                matInput
                autocomplete="lastName"
                formControlName="lastName"
              />
              <mat-error
                *ngIf="editProfileForm.get('lastName').hasError('required')"
              >
                {{ "Last Name is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="editProfileForm.get('lastName').hasError('pattern')"
              >
                {{ "Numbers are not allowed in First Name field" | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="my-form-field profile">
              <mat-label>{{ "Gender" | translate }}</mat-label>
              <mat-select formControlName="gender">
                <mat-option [value]="1">{{ "Male" | translate }}</mat-option>
                <mat-option [value]="2">{{ "Female" | translate }}</mat-option>
                <mat-option [value]="3">{{ "Others" | translate }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="editProfileForm.get('gender').hasError('required')"
              >
                {{ "Gender is Required." | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="my-form-field profile">
              <mat-label>{{ "Date of Birth" | translate }}</mat-label>
              <input
                readonly
                matInput
                [matDatepicker]="picker"
                [max]="maxDate"
                formControlName="dateOfBirth"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error
                *ngIf="editProfileForm.get('dateOfBirth').hasError('required')"
              >
                {{ "Date of Birth is Required." | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="my-form-field profile">
              <mat-label>{{ "About Me" | translate }}</mat-label>
              <input
                type="text"
                matInput
                autocomplete="about"
                formControlName="about"
              />
              <mat-error
                *ngIf="editProfileForm.get('about').hasError('required')"
              >
                {{ "About is Required." | translate }}
              </mat-error>
            </mat-form-field>
          </mat-card>
          <div class="grid-item" style="padding-top: 2%">
            <mat-card style="min-height: 200px">
              <p style="text-align: initial; color: #46535b">
                {{ "Contact Information" | translate }}
              </p>

              <mat-form-field class="my-form-field profile">
                <mat-label>{{ "Email" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  autocomplete="email"
                  formControlName="email"
                />
                <mat-error
                  *ngIf="editProfileForm.get('email').hasError('required')"
                >
                  {{ "Email is Required." | translate }}
                </mat-error>
              </mat-form-field>
              <div style="display: flex">
                <mat-form-field
                  class="my-form-field profile"
                  style="width: 10%; margin-right: 0px"
                >
                  <mat-label>{{ "Code" | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    autocomplete="countryCode"
                    formControlName="countryCode"
                    (keypress)="_keyPress($event)"
                  />
                  <mat-error
                    *ngIf="
                      editProfileForm.get('countryCode').hasError('required')
                    "
                  >
                    {{ "Country Code is Required." | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      editProfileForm.get('countryCode').hasError('maxlength')
                    "
                  >
                    {{
                      "Country Code should be maximum of 3 digits" | translate
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field
                  class="my-form-field profile"
                  style="width: 85%; margin-right: 0px; padding-left: 5%"
                >
                  <mat-label>{{ "Phone Number" | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    autocomplete="phoneNumber"
                    formControlName="phoneNumber"
                    (keypress)="_keyPress($event)"
                  />
                  <mat-error
                    *ngIf="
                      editProfileForm.get('phoneNumber').hasError('required')
                    "
                  >
                    {{ "Phone Number is Required." | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      editProfileForm.get('phoneNumber').hasError('minlength')
                    "
                  >
                    {{
                      "Phone Number should be minimum of 10 digits" | translate
                    }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      editProfileForm.get('phoneNumber').hasError('maxlength')
                    "
                  >
                    {{
                      "Phone Number should be maximum of 12 digits" | translate
                    }}
                  </mat-error>
                </mat-form-field>
              </div>

              <p style="text-align: initial; color: #46535b">
                {{ "Company Information" | translate }}
              </p>
              <div class="provider-list-container">
                <div
                  class="provider-list-item"
                  *ngFor="let provider of profileProviders"
                >
                  {{ provider.name }}
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="grid-item">
          <mat-card style="padding-top: 1%">
            <p style="text-align: initial; color: #46535b">
              {{ "Speciality Information" | translate }}
            </p>

            <mat-form-field class="my-form-field profile">
              <mat-label>{{ "Identification number" | translate }}</mat-label>
              <input
                type="text"
                matInput
                autocomplete="identificationNo"
                formControlName="identificationNo"
              />
              <mat-error
                *ngIf="
                  editProfileForm.get('identificationNo').hasError('required')
                "
              >
                {{ "Identification Number is Required." | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="my-form-field profile">
              <mat-label>{{ "Title/Speciality" | translate }}</mat-label>
              <mat-select
                formControlName="speciality"
                multiple
                (click)="showAndHideSpeciality()"
                (selectionChange)="onSubspecialitySelectionChange('speciality', $event)"
              >
                <mat-option *ngFor="let sp of specialityList" [value]="sp">{{
                  sp.speciality[currentLanguage]
                }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="editProfileForm.get('speciality').hasError('required')"
              >
                {{ "Speciality is Required." | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="my-form-field profile"
              *ngIf="showOtherSpeciality"
            >
              <mat-label>{{ "Other Speciality" | translate }}</mat-label>
              <input type="text" matInput formControlName="otherSpeciality" />
              <mat-error
                *ngIf="
                  editProfileForm.get('otherSpeciality').hasError('required')
                "
              >
                {{ "Other Speciality is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  editProfileForm.get('otherSpeciality').hasError('pattern')
                "
              >
                {{
                  "Numbers are not allowed in other Speciality field"
                    | translate
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="my-form-field profile">
              <mat-label>{{ "Sub-Speciality" | translate }}</mat-label>
              <mat-select
                formControlName="subspeciality"
                multiple
                (click)="showAndHideSubSpeciality()"
                (selectionChange)="onSubspecialitySelectionChange('subspeciality', $event)"
              >
                <mat-option *ngFor="let sub of subSpecialityList" [value]="sub"
                  >{{ sub.subSpeciality[currentLanguage] }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  editProfileForm.get('subspeciality').hasError('required')
                "
              >
                {{ "Sub-Speciality is Required." | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              class="my-form-field profile"
              *ngIf="showOtherSubSpeciality"
            >
              <mat-label>{{ "Other Sub-Speciality" | translate }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="otherSubSpeciality"
              />
              <mat-error
                *ngIf="
                  editProfileForm.get('otherSubSpeciality').hasError('required')
                "
              >
                {{ "Other Sub-Speciality is Required." | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  editProfileForm.get('otherSubSpeciality').hasError('pattern')
                "
              >
                {{
                  "Numbers are not allowed in other Sub-Speciality field"
                    | translate
                }}
              </mat-error>
            </mat-form-field>
            @if ( isSpecialistDoctor ) {

              <mat-form-field class="my-form-field profile">
                <mat-label>{{ "Specialty to attend" | translate }}</mat-label>
                <mat-select
                  #specialtyServedSelect
                  formControlName="specialtyServed"
                  multiple
                >
                  <mat-option
                    [value]="0"
                    (click)="selectAllSepecialtiesServed()"
                    class="bottom-grey"
                  >
                    <h4 class="all-text">
                      {{ "All" | translate }}
                      <small>({{ specialtyServedList.length }})</small>
                    </h4>
                  </mat-option>
                  <mat-option
                    *ngFor="let sub of specialtyServedList"
                    [value]="sub.id"
                    >
                    
                    @if ( sub.subSpeciality ) {
                      {{ sub.subSpeciality[currentLanguage] }}
                    }
                    @else {
                      {{ sub.speciality[currentLanguage] }}
                    }

                    <!-- {{ sub.specialtyServed[currentLanguage] }} -->
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    editProfileForm.get('specialtyServed').hasError('required')
                  "
                >
                  {{ "Specialty to attend is Required." | translate }}
                </mat-error>
              </mat-form-field>

            }
            <div class="container-cm-mpps">
              <mat-form-field class="my-form-field profile">
                <mat-label>{{ "CM Number" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  autocomplete="cmNo"
                  formControlName="cmNO"
                  (keydown)="_keyPress($event)"
                />
                <mat-error
                  *ngIf="editProfileForm.get('cmNO').hasError('required')"
                >
                  {{ "CM Number is Required." | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="my-form-field profile">
                <mat-label>{{ "MPPS Number" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  autocomplete="mppsNo"
                  formControlName="mppsNo"
                  (keypress)="_keyPress($event)"
                />
                <mat-error
                  *ngIf="editProfileForm.get('mppsNo').hasError('required')"
                >
                  {{ "MPPS Number is Required." | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <ng-container *ngIf="!hasFirmPicture">
              <div class="sign-container">
                <mat-form-field class="my-form-field profile">
                  <input
                    type="text"
                    placeholder="Firma"
                    matInput
                    formControlName="signature"
                    readonly
                  />
                  <mat-error>
                    {{ signatureError.message }}
                  </mat-error>
                </mat-form-field>

                <div
                  *ngIf="editProfileForm.enabled"
                  class="sig-container"
                  #sigContainer1
                  (window:resize)="beResponsive()"
                  (onload)="beResponsive()"
                >
                  <signature-field></signature-field>
                </div>
                <button
                  *ngIf="editProfileForm.enabled"
                  class="delete-signature"
                  (click)="clear()"
                >
                  Borrar
                </button>
              </div>
            </ng-container>

            <!-- <input
              type="text"
              matInput
              autocomplete="mppsNo"
              formControlName="mppsNo"
              placeholder="{{ 'MPPS Number' | translate }}"
              (keypress)="_keyPress($event)"
            /> -->
            <!-- <mat-error
              *ngIf="editProfileForm.get('signatureImg').hasError('required')"
            >
              {{ "MPPS Number is Required." | translate }}
            </mat-error> -->
          </mat-card>
        </div>
      </div>

      <div class="register-button-row" *ngIf="savebuttonshow">
        <button
          type="submit"
          id="saveBtn"
          mat-raised-button
          class="large-button-blue"
          style="width: 300px !important"
        >
          {{ "Save" | translate }}
        </button>
      </div>
      <div class="register-cancel-row" *ngIf="savebuttonshow">
        <span style="cursor: pointer" (click)="navigateToHome()">{{
          "Cancel" | translate
        }}</span>
      </div>
      <br /><br />
    </form>
  </div>
</div>
