<div class="container" *ngIf="!loading">
  <div id="#enableSummary" class="switch-config-button">
    <h5 class="Headingtoggle" *ngIf="testCall">
      Is this Test call?
      <mat-slide-toggle
        [color]="'primary'"
        style="text-align: center; padding-left: 15%"
        checked="currentVisitDetails.isTestCall"
        disabled
      >
      </mat-slide-toggle>
    </h5>
  </div>
  <mat-divider></mat-divider>
  <form
    [formGroup]="scheduleFollowUpAppointmentForm"
    (ngSubmit)="scheduleFollowUpAppointmentFormSubmit()"
    *ngIf="showScheduleFollowUpAppointmentFormAbove"
  >
  @if ( doctorRole && doctorRole !== 2 ) {
    <div class="schedule-follow-up-appointment">
      <div class="schedule-follow-up-appointment-left">
        <div
          class="schedule-follow-up"
          (click)="openScheduleFollowUpConsultation()"
        >
          <mat-icon> add_call </mat-icon>
          <div class="heading">
            {{ "Schedule follow-up" | translate }}
          </div>
        </div>

        <mat-icon
          class="info"
          matTooltip="{{
            'Until 12:00 am you can edit the tracking date' | translate
          }}."
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'message-tooltip'"
          >info_outlined</mat-icon
        >
      </div>

      <div class="schedule-follow-up-appointment-right">
        <div class="follow-up-date">
          <div class="heading">
            {{ "Follow up date" | translate }}
          </div>

          <span *ngIf="!scheduleDate">{{ "Not scheduled" | translate }}</span>
          <span *ngIf="scheduleDate">{{
            scheduleDate | date: "dd/MM/yyyy"
          }}</span>
        </div>

        <button
          type="submit"
          style="
            background: none;
            cursor: pointer;
            color: #65a7f6;
            border: none;
          "
          class="update-btn"
        >
          <img
            class="icon"
            style="vertical-align: bottom"
            src="assets/images/save.svg"
          />
          <span style="vertical-align: super">{{ "Update" | translate }}</span>
        </button>
      </div>
    </div>
  }
  </form>
  <mat-divider></mat-divider>
  <br />
  <br />
  <form [formGroup]="form" (ngSubmit)="formSubmit()" *ngIf="!testCall">
    <!-- Chief Complaint Start -->
    <div>
      <div style="float: right">
        {{isEditTimeOver}}
        {{anyFollowUpInTimeForEdit}}
        {{lastConsultation.doctor._id}}
        {{currentDoctorId}}
        
        <!-- {{enableUpdateButton}}
        {{enableButton}} -->
        @if ( !enableUpdateButton ) {
          <button
            type="button"
            style="background: none; float: none; border: none"
            (click)="
              (isEditTimeOver && !anyFollowUpInTimeForEdit) ||
              lastConsultation.doctor._id !== currentDoctorId
                ? null
                : enableUpdateBtn()
            "
            
            [ngClass]="enableButton ? 'show-btn' : 'hide-btn'"
            [style.color]="
              (!isEditTimeOver || anyFollowUpInTimeForEdit) &&
              lastConsultation.doctor._id === currentDoctorId
                ? '#65a7f6'
                : '#92a0a5'
            "
            [style.cursor]="
              (!isEditTimeOver || anyFollowUpInTimeForEdit) &&
              lastConsultation.doctor._id === currentDoctorId
                ? 'pointer'
                : 'not-allowed'
            "
          >
          <!-- normal -->

            @if ( isEditTimeOver && !anyFollowUpInTimeForEdit || lastConsultation.doctor._id !== currentDoctorId) {
              <!-- deshabilitado -->
              <img
                class="icon disabled"
                style="vertical-align: middle"
                src="assets/images/editi-disabled.png"
              />
            }
            @else {
              <!-- habilitado -->
              <img
                class="icon"
                style="vertical-align: middle"
                src="assets/images/editi.svg"
              />
            }
          
            <span style="vertical-align: middle"> 
              {{ "Edit" | translate }} 
            </span>

          </button>
        }
        @else {
          <button
            type="submit"
            style="
              background: none;
              float: none;
              cursor: pointer;
              color: #65a7f6;
              border: none;
            "
            class="update-btn"
          >
            <img
              class="icon"
              style="vertical-align: middle"
              src="assets/images/save.svg"
            />
            <span style="vertical-align: middle">{{ "Update" | translate }}</span>
          </button>
        }

        <button type="button" mat-button [matMenuTriggerFor]="menuDownload">
          <mat-icon style="color: #65a7f6">download</mat-icon>
          <span style="color: #65a7f6">{{ "Download" | translate }}</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu
          #menuDownload="matMenu"
          class="download-menu"
          xPosition="before"
          yPosition="below"
        >
          <button mat-menu-item (click)="downloadMedicalReport()">
            <mat-icon>note_add</mat-icon>
            {{ "Medical report" | translate }}
          </button>

          <button
            mat-menu-item
            *ngIf="currentVisitDetails?.medicalPrescription?.length >= 1"
            (click)="downloadMedicalPrescription()"
          >
            <mat-icon>library_add</mat-icon>
            {{ "Medical prescription" | translate }}
          </button>

          <button
            mat-menu-item
            (click)="downloadMedicalReportAndPrescription()"
          >
            <mat-icon>download</mat-icon>
            {{ "All" | translate }}
          </button>
        </mat-menu>
      </div>
      <div>
        <!-- <h5 class="heading">{{ "Reason for visit" | translate }}</h5> -->
        <span class="sub-headings">
          <mat-form-field style="width: 25%" class="my-form-field">
            <!-- <mat-label>{{ "Reason for visit" | translate }}</mat-label>

            <ng-container *ngIf="isNewChiefComplaintList; else oldChiefComplaint">
              <mat-select  formControlName="chiefComplaint">
                <mat-option *ngFor="let c of chiefComplaintList" [value]="c">{{
                  c.chiefComplaint[currentLanguage]
                }}</mat-option>
              </mat-select>

            </ng-container>
            <ng-template #oldChiefComplaint>
              <input matInput type="text" *ngIf="!isNewChiefComplaintList" formControlName="chiefComplaint"/>
            </ng-template>

 -->

            <mat-label>{{ "Reason for visit" | translate }} </mat-label>
            <input
              type="text"
              aria-label="Number"
              matInput
              formControlName="chiefComplaint"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let c of filteredOptions | async"
                [value]="c"
                >{{ c }}</mat-option
              >
            </mat-autocomplete>
          </mat-form-field>

          <!-- <mat-form-field
            class="my-form-field"
            style="width: 25%; padding-left: 2%"
            *ngIf="showOtherChiefComplaint"
          >
            <input
              type="text"
              matInput
              placeholder="{{ 'Other ChiefComplaint' | translate }}"
              formControlName="otherChiefComplaint"
            />
          </mat-form-field> -->
        </span>
      </div>

      <div>
        <h5 class="heading">{{ "Pregnancy status" | translate }}</h5>
        <ng-container [ngSwitch]="currentVisitDetails?.isPregnant">
          <span class="sub-headings" *ngSwitchCase="1">{{
            "Yes" | translate
          }}</span>
          <span class="sub-headings" *ngSwitchCase="2">{{
            "No" | translate
          }}</span>
          <span class="sub-headings" *ngSwitchCase="3">{{
            "Not sure" | translate
          }}</span>
        </ng-container>
      </div>
      <br />
      <div>
        <h5 class="heading">{{ "Photos" | translate }}</h5>
        <div class="row">
          <div class="column">
            <span *ngFor="let a of currentVisitDetails.document; let i = index">
              <span (click)="attachmentPopup(a)">
                <img
                  *ngIf="documentsGetImage(a) === 'jpg'"
                  src="{{ a.url }}"
                  style="width: 100px; height: 100px; margin: 10px"
                />
                <img
                  *ngIf="documentsGetImage(a) === 'png'"
                  src="{{ a.url }}"
                  style="width: 100px; height: 100px; margin: 10px"
                />
                <img
                  *ngIf="documentsGetImage(a) === 'pdf'"
                  src="../../../assets/images/pdf-view.png"
                  style="width: 100px; height: 100px; margin: 10px"
                />
                <img
                  *ngIf="documentsGetImage(a) === 'doc'"
                  src="../../../assets/images/word.svg"
                  style="width: 100px; height: 100px; margin: 10px"
                />
                <img
                  *ngIf="documentsGetImage(a) === 'xsl'"
                  src="../../../assets/images/excel.svg"
                  style="width: 100px; height: 100px; margin: 10px"
                />
              </span>
            </span>
            <div *ngIf="currentVisitDetails.document < 1">
              {{ "No Photos Available" | translate }}
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
    <br />
    <mat-divider></mat-divider>

    <!-- Chief Complaint End -->

    <br /><br />

    <!-- History of present illness Start-->
    <div>
      <app-text-area
        controller="historyOfPresentIllness"
        [data]="historyOfPresentIllness"
        label="{{ 'History of present illnesses' | translate }}"
        (change)="setHistoryOfPresentIllness($event)"
        [edit]="enableUpdate"
        [valid]="isPresentIllnessValid"
      ></app-text-area>
    </div>
    <!-- History of present illness End-->
    <br />
    <mat-divider></mat-divider>
    <br /><br />

    <!-- Review Of System Start-->
    <div>
      <h6 class="subheading">{{ "Review Of System" | translate }}</h6>

      <div formArrayName="reviewOfSystem">
        <div
          *ngFor="
            let P of form['controls'].reviewOfSystem['controls'];
            let ir = index
          "
        >
          <div formGroupName="{{ ir }}">
            <mat-form-field style="width: 20%" class="my-form-field">
              <mat-select
                formControlName="bodySystem"
                placeholder="{{ 'Problem' | translate }}"
              >
                <mat-option
                  *ngFor="let system of bodysystem"
                  [value]="system._id"
                >
                  {{ system.bodySystem[currentLanguage] }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="my-form-field"
              style="width: 65%; padding-right: 2%"
            >
              <mat-label>{{ "Tell What.." | translate }}</mat-label>
              <input matInput placeholder="" formControlName="value" />
            </mat-form-field>
            <button
              mat-icon-button
              [disabled]="!enableUpdate"
              [ngClass]="enableUpdate ? 'circle' : 'noAction'"
              *ngIf="
                enableUpdate &&
                form['controls'].reviewOfSystem['controls'].length - 1 == ir
              "
              (click)="addReviewOfSystem()"
            >
              <!-- <img src="../../../assets/images/addnew.svg" /> -->
              <mat-icon>
                <img src="../../../assets/images/add.svg" />
              </mat-icon>
            </button>
            <button
              mat-icon-button
              [disabled]="!enableUpdate"
              class="circle"
              *ngIf="
                enableUpdate &&
                form['controls'].reviewOfSystem['controls'].length > 1
              "
              (click)="removeReviewOfSystem(ir)"
            >
              <mat-icon
                ><img
                  style="margin-left: 5px"
                  src="../../../assets/images/delete-all.svg"
              /></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Review Of System End-->
    <br />
    <mat-divider></mat-divider>
    <br /><br />

    <!-- Vital Information Start -->
    <div class="vital">
      <h6 class="subheading">{{ "Vital signs" | translate }}</h6>
      <mat-form-field class="my-form-field" style="width: 17%">
        <span matSuffix>{{ "mmHg" | translate }} </span>
        <input
          type="number"
          matInput
          min="0"
          placeholder="{{ 'BPSystolic' | translate }}"
          formControlName="bloodPressureSystolic"
          (keypress)="_keyPress($event)"
        />
      </mat-form-field>

      <mat-form-field class="my-form-field" style="width: 17%">
        <span matSuffix>{{ "mmHg" | translate }} </span>
        <input
          type="number"
          matInput
          min="0"
          placeholder="{{ 'BPDiastolic' | translate }}"
          formControlName="bloodPressureDiastolic"
          (keypress)="_keyPress($event)"
        />
      </mat-form-field>

      <mat-form-field class="my-form-field" style="width: 17%">
        <span matSuffix>{{ "rbm" | translate }} </span>
        <input
          type="number"
          matInput
          min="0"
          placeholder="{{ 'HeartRate' | translate }}"
          formControlName="heartRate"
          (keypress)="_keyPress($event)"
        />
      </mat-form-field>
      <mat-form-field class="my-form-field" style="width: 17%">
        <span matSuffix>{{ "bpm" | translate }} </span>
        <input
          type="number"
          min="0"
          matInput
          placeholder="{{ 'Respiratory' | translate }}"
          formControlName="respiratoryRate"
          (keypress)="_keyPress($event)"
        />
      </mat-form-field>
      <mat-form-field class="my-form-field" style="width: 17%">
        <span matSuffix>{{ "`C" | translate }} </span>
        <input
          type="number"
          matInput
          placeholder="{{ 'Temperature' | translate }}"
          formControlName="temperature"
          (keypress)="_keyPress($event)"
        />
      </mat-form-field>
    </div>
    <!-- Vital Information End -->
    <br />
    <mat-divider></mat-divider>
    <br /><br />

    <!-- Medical Information Start -->
    <div>
      <h6 class="subheading">{{ "Medical Information" | translate }}</h6>
      <!-- <mat-form-field class="my-form-field" style="width: 100%"> -->
      <!-- <input
          matInput
          placeholder="{{ 'Paraclinics' | translate }}"
          formControlName="paraclinics"
        /> -->
      <app-text-area
        controller="paraclinics"
        [data]="paraclinics"
        label="{{ 'Paraclinics' | translate }}"
        (change)="setParaclinics($event)"
        [edit]="enableUpdate"
      ></app-text-area>
      <!-- </mat-form-field> -->
      <!-- <mat-form-field class="my-form-field" style="width: 100%">
        <input
          matInput
          placeholder="{{ 'Differential Diagnosis' | translate }}"
          formControlName="differentialDiagnosis"
        />
      </mat-form-field> -->
      <!-- TODO -->

      <mat-form-field style="width: 100%" class="my-form-field">
        <mat-label>{{ "Differential Diagnosis" | translate }}</mat-label>
        <input
          type="text"
          aria-label="string"
          matInput
          formControlName="differentialDiagnosisCie11"
          (ngModelChange)="onChangeDifferentDiagnosisCie11($event)"
          [matAutocomplete]="diffAuto"
        />

        <mat-autocomplete #diffAuto="matAutocomplete">
          <mat-option
            (click)="
              addDiagnosis(
                diagnostic,
                differentialDiagnosisListSelected,
                'differentialDiagnosisCie11'
              )
            "
            *ngFor="let diagnostic of differentialDiagnosisListFiltered"
            [value]="diagnostic"
            >{{ diagnostic?.title }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
      <div
        *ngIf="
          (!enableUpdate && differentialDiagnosisListSelected.length > 0) ||
          enableUpdate
        "
        class="card-container"
      >
        <div
          class="card"
          *ngFor="let card of differentialDiagnosisListSelected"
        >
          {{ transformTitle(card?.title ? card?.title : card?.cie11) }}
          <mat-icon
            *ngIf="enableUpdate"
            (click)="removeDiffDiagnosis(card?._id)"
            >close</mat-icon
          >
        </div>
      </div>
      <div
        class="card-container"
        *ngIf="!enableUpdate && differentialDiagnosisListSelected.length < 1"
        [innerHTML]="oldDifferentialDiagnosis"
      ></div>
      <!--     <app-text-area
        controller="differentialDiagnosis"
        [data]="differentialDiagnosis"
        label="{{ 'Differential Diagnosis' | translate }}"
        (change)="setDifferentialDiagnosis($event)"
        [edit]="enableUpdate"
      ></app-text-area> -->
      <!-- <mat-form-field class="my-form-field" style="width: 100%">
        <input
          matInput
          placeholder="{{ 'Diagnosis' | translate }}"
          formControlName="diagnosis"
        />
      </mat-form-field> -->
      <!-- TODO -->
      <!--   <app-text-area
        controller="diagnosis"
        [data]="diagnosis"
        label="{{ 'Diagnosis' | translate }}"
        (change)="setDiagnosis($event)"
        [edit]="enableUpdate"
        [valid]="isDiagnosisValid"
      ></app-text-area> -->
      <mat-form-field style="width: 100%" class="my-form-field">
        <mat-label>{{ "Diagnosis" | translate }}</mat-label>
        <input
          type="text"
          aria-label="string"
          matInput
          formControlName="diagnosisCie11"
          (ngModelChange)="onChangeDiagnosisCie11($event)"
          [matAutocomplete]="diagAuto"
        />
        <mat-autocomplete #diagAuto="matAutocomplete">
          <mat-option
            (click)="
              addDiagnosis(diagnostic, diagnosticListSelected, 'diagnosisCie11')
            "
            *ngFor="let diagnostic of diagnosticListFiltered"
            [value]="diagnostic"
            >{{ diagnostic?.title }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
      <div
        *ngIf="
          (!enableUpdate && diagnosticListSelected.length > 0) || enableUpdate
        "
        class="card-container"
      >
        <div class="card" *ngFor="let card of diagnosticListSelected">
          {{ transformTitle(card?.title ? card?.title : card?.cie11) }}
          <mat-icon *ngIf="enableUpdate" (click)="removeDiagnosis(card?._id)">
            close</mat-icon
          >
        </div>
      </div>
      <div
        class="card-container"
        *ngIf="!enableUpdate && diagnosticListSelected.length < 1"
        [innerHTML]="oldDiagnosis"
      ></div>
      <!-- <mat-form-field class="my-form-field" style="width: 100%">
        <input
          matInput
          placeholder="{{ 'Plan' | translate }}"
          formControlName="plan"
        />
        <mat-error *ngIf="form.get('plan').hasError('required')">
          {{ "Plan is Required." | translate }}
        </mat-error>
      </mat-form-field> -->
      <app-text-area
        controller="plan"
        [data]="plan"
        label="{{ 'Plan' | translate }}"
        (change)="setPlan($event)"
        [edit]="enableUpdate"
        [valid]="isPlanValid"
      ></app-text-area>

      <form formGroupName="closeAttentionForm">
        <div
          class="end-of-medical-care"
          *ngIf="!visit.hasFollowUp && canCloseAttention"
        >
          <div class="completed-medical-care switch-config-button">
            <span *ngIf="!closedAttention" style="margin-right: 20px">{{
              "Closing this attention" | translate
            }}</span>
            <span *ngIf="closedAttention">{{
              "Completed medical care" | translate
            }}</span>
            <mat-slide-toggle
              #slideCloseAttention
              formControlName="closeAttention"
              (change)="closeAttentionChange($event)"
              id="slide-question"
              class="test-call-toggle"
            >
              <span
                *ngIf="closeAttentionForm.enabled"
                class="slide-question-label"
                [ngClass]="{ checked: slideCloseAttention.checked }"
              >
                {{ (slideCloseAttention.checked ? "Yes" : "No") | translate }}
              </span>
            </mat-slide-toggle>
          </div>

          <div *ngIf="slideCloseAttention.checked" class="closure-reason">
            <div class="left-side">
              <mat-form-field class="select-reason">
                <mat-label
                  >{{ "Query closure reason" | translate }} *</mat-label
                >
                <mat-select
                  formControlName="closureReason"
                  class="closure-reason-select"
                  (selectionChange)="closureReasonChange($event)"
                  (opened)="closureReasonOpened()"
                >
                  <mat-option
                    *ngFor="let closureReason of closureReasons"
                    [value]="closureReason._id"
                    class="multiline-mat-option reason-option"
                  >
                    <div
                      *ngIf="
                        closureReason.isOther;
                        then isOtherBlock;
                        else isNotOtherBlock
                      "
                    ></div>

                    <ng-template #isOtherBlock>
                      <span
                        *ngIf="
                          !closureReasonSelectIsOpen &&
                            !isOtherClosureReason &&
                            !!closeAttentionForm.get('otherClosureReason')
                              .value;
                          else isNotOtherClosureReason
                        "
                      >
                        {{ closeAttentionForm.get("otherClosureReason").value }}
                      </span>

                      <ng-template #isNotOtherClosureReason>{{
                        closureReason.closureReason[currentLanguage]
                      }}</ng-template>
                    </ng-template>

                    <ng-template #isNotOtherBlock>
                      {{ closureReason.closureReason[currentLanguage] }}
                    </ng-template>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="right-side" *ngIf="isOtherClosureReason">
              <mat-form-field>
                <mat-label>{{ "Reason for closure" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="otherClosureReason"
                  maxlength="35"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>

      <!-- <mat-form-field class="my-form-field" style="width: 100%">
        <input
          matInput
          placeholder="{{ 'Doctor Notes' | translate }}"
          formControlName="doctorNotes"
        />
      </mat-form-field> -->
      <app-text-area
        controller="doctorNotes"
        [data]="doctorNotes"
        label="{{ 'Doctor Notes' | translate }}"
        (change)="setDoctorNotes($event)"
        [edit]="enableUpdate"
      ></app-text-area>
    </div>

    <!-- <div *ngIf="!enableUpdate">
      <h6 class="subheading">{{ "Medical Information" | translate }}</h6>
      <mat-label class="heading">{{ "Paraclinics" | translate }}</mat-label>
      <p>{{ this.form.get("paraclinics").value }}</p>
      <br />
      <mat-label class="heading">{{
        "Differential Diagnosis" | translate
      }}</mat-label>
      <p>{{ this.form.get("differentialDiagnosis").value }}</p>
      <br />
      <mat-label class="heading">{{ "Diagnosis" | translate }}</mat-label>
      <p>{{ this.form.get("diagnosis").value }}</p>
      <br />
      <mat-label class="heading">{{ "Plan" | translate }}</mat-label>
      <p>{{ this.form.get("plan").value }}</p>
      <br />
      <mat-label class="heading">{{ "Doctor Notes" | translate }}</mat-label>
      <p>{{ this.form.get("doctorNotes").value }}</p>
    </div> -->

    <br />
    <mat-divider></mat-divider>
    <!-- Medical Information End -->
    <br /><br />

    <!-- Paraclinical Prescription Start-->
    <div>
      <h6 class="subheading">{{ "Paraclinical Prescription" | translate }}</h6>

      <div formArrayName="paraclinicalPrescription">
        <div
          *ngFor="
            let P of form['controls'].paraclinicalPrescription['controls'];
            let ip = index
          "
        >
          <div formGroupName="{{ ip }}">
            <mat-form-field style="width: 20%" class="my-form-field">
              <mat-label>{{ "Title" | translate }}</mat-label>
              <input matInput placeholder="" formControlName="title" />
            </mat-form-field>
            <mat-form-field class="my-form-field" style="width: 65%">
              <mat-label>{{ "Description" | translate }}</mat-label>
              <input matInput placeholder="" formControlName="description" />
            </mat-form-field>
            <button
              mat-icon-button
              [disabled]="!enableUpdate"
              [ngClass]="enableUpdate ? 'circle' : 'noAction'"
              *ngIf="
                enableUpdate &&
                form['controls'].paraclinicalPrescription['controls'].length -
                  1 ==
                  ip
              "
              (click)="addParaClinicalPrescription()"
            >
              <!-- <img src="../../../assets/images/addnew.svg" /> -->
              <mat-icon>
                <img src="../../../assets/images/add.svg" />
              </mat-icon>
            </button>
            <button
              mat-icon-button
              [disabled]="!enableUpdate"
              [ngClass]="enableUpdate ? 'circle' : 'noAction'"
              *ngIf="
                enableUpdate &&
                form['controls'].paraclinicalPrescription['controls'].length > 1
              "
              (click)="removeParaClinicalPrescription(ip, P)"
            >
              <mat-icon
                ><img
                  style="margin-left: 5px"
                  src="../../../assets/images/delete-all.svg"
              /></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Paraclinical Prescription End-->
    <br />
    <mat-divider></mat-divider>
    <br /><br />

    <!-- Medical prescription Start-->
    <div>
      <!-- X -->
      <div formArrayName="medicalPrescription">
        <div
          *ngFor="
            let X of form['controls'].medicalPrescription['controls'];
            let ix = index
          "
        >
          <div class="primary-container">
            <h6 class="subheading">{{ "Medical Prescription" | translate }}</h6>
            <div formGroupName="{{ ix }}" class="medical-prescription-grid">
              <mat-form-field class="medical-presc-field">
                <mat-label
                  >{{ "Medication Name" | translate }} {{ ix + 1 }}</mat-label
                >
                <input
                  matInput
                  placeholder=""
                  formControlName="name"
                  (input)="getNameUpdate($event, ix, 0)"
                />
              </mat-form-field>
              <!-- <p>{{ formErrors.medicalPrescription[ix].name }}</p> -->
              <mat-form-field class="medical-presc-field" style="width: 100px">
                <mat-label>{{ "Dosage" | translate }}</mat-label>
                <input matInput placeholder="" formControlName="dosage" />
                <!-- <mat-error>
                Dosage is Required.
              </mat-error> -->
              </mat-form-field>
              <!-- <p>{{ formErrors.medicalPrescription[ix].dosage }}</p> -->
              <mat-form-field
                style="width: 150px"
                class="medical-presc-field"
                *ngIf="!enableUpdate"
              >
                <mat-label>{{
                  "international unit (s)" | translate
                }}</mat-label>
                <input
                  matInput
                  placeholder=""
                  disabled
                  [value]="getUnitName(ix)"
                />
              </mat-form-field>
              <mat-form-field
                style="width: 150px"
                class="medical-presc-field"
                [hidden]="!enableUpdate"
              >
                <mat-label [hidden]="!enableUpdate">{{
                  "international unit (s)" | translate
                }}</mat-label>

                <mat-select formControlName="unit" [hidden]="!enableUpdate">
                  <mat-option *ngFor="let u of Units" [value]="u._id">{{
                    u.unit[currentLanguage]
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <p>{{ formErrors.medicalPrescription[ix].unit }}</p> -->
              <mat-form-field style="width: 150px" class="medical-presc-field">
                <mat-label>{{ "Route (s)" | translate }}</mat-label>

                <mat-select formControlName="routeOfAdministration">
                  <mat-option
                    *ngFor="let admin of adminstrations"
                    [value]="admin._id"
                  >
                    {{
                      admin.routeOfAdministration[currentLanguage]
                    }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <!-- <p>{{ formErrors.medicalPrescription[ix].routeOfAdministration }}</p> -->
              <mat-form-field class="medical-presc-field" style="width: 100px">
                <mat-label>{{ "Frequency" | translate }}</mat-label>
                <input
                  type="number"
                  min="0"
                  matInput
                  placeholder=""
                  formControlName="frequency"
                  (keypress)="_keyPress($event)"
                />
              </mat-form-field>
              <!-- <p>{{ formErrors.medicalPrescription[ix].frequency }}</p> -->
              <mat-form-field style="width: 100px" class="medical-presc-field">
                <mat-label>{{ "units" | translate }}</mat-label>
                <mat-select formControlName="frequencyTime">
                  <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                    t.time[currentLanguage]
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <p>{{ formErrors.medicalPrescription[ix].frequencyTime }}</p> -->
              <mat-form-field class="medical-presc-field" style="width: 100px">
                <mat-label>{{ "Duration" | translate }}</mat-label>
                <input
                  type="number"
                  matInput
                  min="0"
                  placeholder=""
                  formControlName="duration"
                  (keypress)="_keyPress($event)"
                />
              </mat-form-field>
              <!-- <p>{{ formErrors.medicalPrescription[ix].duration }}</p> -->
              <mat-form-field style="width: 100px" class="medical-presc-field">
                <mat-label>{{ "units" | translate }}</mat-label>
                <mat-select formControlName="durationTime">
                  <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                    t.time[currentLanguage]
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <p>{{ formErrors.medicalPrescription[ix].durationTime }}</p> -->
              <!-- <input type="text" formControlName="notes"> -->
              <button
                mat-icon-button
                [disabled]="!enableUpdate"
                class="circle"
                *ngIf="
                  enableUpdate &&
                  X['controls'].alternative['controls'].length - 1 == -1
                "
                (click)="addY(ix, 0)"
              >
                <mat-icon>
                  <img src="../../../assets/images/add.svg" />
                </mat-icon>
              </button>
              <button
                mat-icon-button
                type="button"
                [disabled]="!enableUpdate"
                class="delbtn"
                *ngIf="
                  enableUpdate &&
                  form['controls'].medicalPrescription['controls'].length > 1
                "
                (click)="removeX(ix, X, 0)"
              >
                <mat-icon
                  ><img
                    style="margin-left: 5px"
                    src="../../../assets/images/delete-all.svg"
                /></mat-icon>
              </button>

              <mat-form-field style="width: 100%" appearance="outline">
                <textarea
                  style="resize: none"
                  class="notes"
                  rows="5"
                  cols="117"
                  placeholder="{{ 'Enter Text....' | translate }}"
                  formControlName="notes"
                  matInput
                ></textarea>
              </mat-form-field>

              <!-- Y -->
              <div formArrayName="alternative">
                <div
                  *ngFor="
                    let Y of X['controls'].alternative['controls'];
                    let iy = index
                  "
                >
                  <div
                    formGroupName="{{ iy }}"
                    class="medical-prescription-grid"
                  >
                    <mat-form-field
                      class="alt-medicine-field"
                      style="width: 50%"
                    >
                      <mat-label
                        >{{ "Alternative" | translate }} -
                        {{ iy + 1 }}</mat-label
                      >
                      <input
                        matInput
                        placeholder=""
                        formControlName="name"
                        (change)="getAlternateNameUpdate($event, ix, iy, 0)"
                      />
                    </mat-form-field>
                    <!-- <p> {{ formErrors.medicalPrescription[ix].alternative[iy].name }}</p> -->
                    <mat-form-field
                      class="alt-medicine-field"
                      style="width: 100px"
                    >
                      <mat-label>{{ "Dosage" | translate }}</mat-label>
                      <input matInput placeholder="" formControlName="dosage" />
                    </mat-form-field>
                    <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].dosage }}</p> -->
                    <mat-form-field
                      style="width: 100px"
                      class="alt-medicine-field"
                    >
                      <mat-label>{{
                        "international unit (s)" | translate
                      }}</mat-label>

                      <mat-select formControlName="unit">
                        <mat-option *ngFor="let u of Units" [value]="u._id">{{
                          u.unit[currentLanguage]
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].unit }}</p> -->
                    <mat-form-field
                      style="width: 100px"
                      class="alt-medicine-field"
                    >
                      <mat-label>{{ "Route (s)" | translate }}</mat-label>

                      <mat-select formControlName="routeOfAdministration">
                        <mat-option
                          *ngFor="let admin of adminstrations"
                          [value]="admin._id"
                        >
                          {{
                            admin.routeOfAdministration[currentLanguage]
                          }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].routeOfAdministration }}</p> -->
                    <mat-form-field
                      class="alt-medicine-field"
                      style="width: 100px"
                    >
                      <mat-label>{{ "Frequency" | translate }}</mat-label>
                      <input
                        type="number"
                        matInput
                        min="0"
                        placeholder=""
                        formControlName="frequency"
                        (keypress)="_keyPress($event)"
                      />
                    </mat-form-field>
                    <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].frequency }}</p> -->
                    <mat-form-field
                      style="width: 100px"
                      class="alt-medicine-field"
                    >
                      <mat-label>{{ "units" | translate }}</mat-label>
                      <mat-select formControlName="frequencyTime">
                        <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                          t.time[currentLanguage]
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].frequencyTime }}</p> -->
                    <mat-form-field
                      class="alt-medicine-field"
                      style="width: 100px"
                    >
                      <mat-label>{{ "Duration" | translate }}</mat-label>
                      <input
                        type="number"
                        matInput
                        min="0"
                        placeholder=""
                        formControlName="duration"
                        (keypress)="_keyPress($event)"
                      />
                    </mat-form-field>
                    <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].duration }}</p> -->
                    <mat-form-field
                      style="width: 100px"
                      class="alt-medicine-field"
                    >
                      <mat-label>{{ "units" | translate }}</mat-label>
                      <mat-select formControlName="durationTime">
                        <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                          t.time[currentLanguage]
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <div (click)="addY(ix, 0)">
                    Add alternative
                  </div> -->
                    <button
                      mat-icon-button
                      [disabled]="!enableUpdate"
                      class="circle"
                      *ngIf="
                        enableUpdate &&
                        X['controls'].alternative['controls'].length - 1 == iy
                      "
                      (click)="addY(ix, 0)"
                    >
                      <mat-icon>
                        <img src="../../../assets/images/add.svg" />
                      </mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      type="button"
                      [disabled]="!enableUpdate"
                      class="delbtn"
                      (click)="removeY(ix, iy, Y, 0)"
                      *ngIf="enableUpdate"
                    >
                      <mat-icon
                        ><img
                          style="margin-left: 5px"
                          src="../../../assets/images/delete-all.svg"
                      /></mat-icon>
                    </button>
                    <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].durationTime }}</p> -->
                    <mat-form-field style="width: 100%" appearance="outline">
                      <textarea
                        style="resize: none"
                        matInput
                        class="notes"
                        rows="5"
                        cols="117"
                        placeholder="{{ 'Enter Text....' | translate }}"
                        formControlName="notes"
                      ></textarea>
                    </mat-form-field>
                    <!-- <p>{{ formErrors.medicalPrescription[ix].alternative[iy].notes }}</p> -->
                  </div>
                </div>
              </div>
              <div
                style="text-align: center"
                *ngIf="
                  enableUpdate &&
                  form['controls'].medicalPrescription['controls'].length - 1 ==
                    ix
                "
              >
                <button
                  mat-button
                  [disabled]="!enableUpdate"
                  class="circle"
                  style="cursor: pointer"
                  (click)="addX(0)"
                >
                  <mat-icon>
                    <img src="../../../assets/images/add.svg" />
                  </mat-icon>
                  <span style="padding-left: 10px; padding-top: 2px">{{
                    "Add Next" | translate
                  }}</span>
                </button>
              </div>
              <!-- Y End-->
            </div>
          </div>
        </div>
      </div>
      <!-- X End -->
    </div>
    <!-- Medical prescription End-->
    <br />
    <mat-divider></mat-divider>
    <br /><br />

    <!-- 
      Follow up Info Start 
      TODO: aqui empieza la logica de seguimiento
    -->
    @if ( !hideFollowUpForm ) {
      <div formGroupName="followUpForm">
        <!-- <mat-card class="cards"> -->
        <h6 class="subheading"> {{ "Follow up Information" | translate }} 1 </h6>
        <app-text-area
          controller="subjective"
          [data]="followUpForm.subjective"
          label="{{ 'Subjective' | translate }}"
          (change)="updateFollowUp('subjective', $event, '')"
          [edit]="isFollowUpEditEnabled"
        ></app-text-area>
        <app-text-area
          controller="objective"
          [data]="followUpForm.objective"
          label="{{ 'Objective' | translate }}"
          (change)="updateFollowUp('objective', $event, '')"
          [edit]="isFollowUpEditEnabled"
        ></app-text-area>
        <!--    <app-text-area
          controller="assesment"
          [data]="followUpForm.assesment"
          label="{{ 'Assessment' | translate }}"
          (change)="updateFollowUp('assesment', $event, '')"
          [edit]="isFollowUpEditEnabled"
        ></app-text-area> -->
        <mat-form-field
          *ngIf="
            (!isFollowUpEditEnabled && followUpForm.assesmentCie11.length > 0) ||
            isFollowUpEditEnabled
          "
          style="width: 100%"
          class="my-form-field"
        >
          <mat-label>{{ "Assessment" | translate }}</mat-label>
          <input
            type="text"
            matInput
            [disabled]="!isFollowUpEditEnabled"
            [(ngModel)]="assessmentValue"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="
              onChangeFollowUp($event, followUpForm.assesmentCie11)
            "
            [matAutocomplete]="diffAssessment"
          />
  
          <mat-autocomplete #diffAssessment="matAutocomplete">
            <mat-option
              (click)="addAssessment(diagnostic, followUpForm.assesmentCie11)"
              *ngFor="let diagnostic of assessmentListFiltered"
              [value]="diagnostic"
              >{{ diagnostic.title }}</mat-option
            >
          </mat-autocomplete>
        </mat-form-field>
        <div
          *ngIf="
            (!isFollowUpEditEnabled && followUpForm.assesmentCie11.length > 0) ||
            isFollowUpEditEnabled
          "
          class="card-container"
        >
          <div class="card" *ngFor="let card of followUpForm.assesmentCie11">
            <span>
              {{ transformTitle(card?.title ? card?.title : card?.cie11) }}</span
            >
            <mat-icon
              *ngIf="isFollowUpEditEnabled"
              (click)="removeAssessmentFollowUp(card._id, 'followUpForm')"
            >
              close</mat-icon
            >
          </div>
        </div>
        <mat-label
          style="color: #92a0a5"
          *ngIf="!isFollowUpEditEnabled && followUpForm.assesmentCie11.length < 1"
          >{{ "Assessment" | translate }}</mat-label
        >
        <div
          class="card-container"
          style="margin-top: 10px"
          *ngIf="!isFollowUpEditEnabled && followUpForm.assesmentCie11.length < 1"
          [innerHTML]="followUpForm.assesment"
        ></div>
        <app-text-area
          controller="plan"
          [data]="followUpForm.plan"
          label="{{ 'Plan' | translate }}"
          (change)="updateFollowUp('plan', $event, '')"
          [edit]="isFollowUpEditEnabled"
        ></app-text-area>
  
        <div
          *ngIf="
            !!visit.hasFollowUp &&
            hideFollowUpForm2 &&
            hideFollowUpForm3 &&
            canCloseAttention
          "
        >
          <form formGroupName="closeAttentionForm">
            <div class="end-of-medical-care">
              <div class="completed-medical-care switch-config-button">
                <span *ngIf="!closedAttention" style="margin-right: 20px">{{
                  "Closing this attention" | translate
                }}</span>
                <span *ngIf="closedAttention">{{
                  "Completed medical care" | translate
                }}</span>
                <mat-slide-toggle
                  #slideCloseAttention
                  formControlName="closeAttention"
                  (change)="closeAttentionChange($event)"
                  id="slide-question"
                  class="test-call-toggle"
                >
                  <span
                    *ngIf="closeAttentionForm.enabled"
                    class="slide-question-label"
                    [ngClass]="{ checked: slideCloseAttention.checked }"
                  >
                    {{ (slideCloseAttention.checked ? "Yes" : "No") | translate }}
                  </span>
                </mat-slide-toggle>
              </div>
  
              <div *ngIf="slideCloseAttention.checked" class="closure-reason">
                <div class="left-side">
                  <mat-form-field class="select-reason">
                    <mat-label
                      >{{ "Query closure reason" | translate }} *</mat-label
                    >
                    <mat-select
                      formControlName="closureReason"
                      class="closure-reason-select"
                      (selectionChange)="closureReasonChange($event)"
                      (opened)="closureReasonOpened()"
                    >
                      <mat-option
                        *ngFor="let closureReason of closureReasons"
                        [value]="closureReason._id"
                        class="multiline-mat-option reason-option"
                      >
                        <div
                          *ngIf="
                            closureReason.isOther;
                            then isOtherBlock;
                            else isNotOtherBlock
                          "
                        ></div>
  
                        <ng-template #isOtherBlock>
                          <span
                            *ngIf="
                              !closureReasonSelectIsOpen &&
                                !isOtherClosureReason &&
                                !!closeAttentionForm.get('otherClosureReason')
                                  .value;
                              else isNotOtherClosureReason
                            "
                          >
                            {{
                              closeAttentionForm.get("otherClosureReason").value
                            }}
                          </span>
  
                          <ng-template #isNotOtherClosureReason>{{
                            closureReason.closureReason[currentLanguage]
                          }}</ng-template>
                        </ng-template>
  
                        <ng-template #isNotOtherBlock>
                          {{ closureReason.closureReason[currentLanguage] }}
                        </ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
  
                <div class="right-side" *ngIf="isOtherClosureReason">
                  <mat-form-field>
                    <mat-label>{{ "Reason for closure" | translate }}</mat-label>
                    <input
                      matInput
                      formControlName="otherClosureReason"
                      maxlength="35"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
  
        <form [formGroup]="mpFollowUpForm">
          <div formArrayName="medicalPrescription">
            <div
              *ngFor="
                let X of mpFollowUpForm['controls'].medicalPrescription[
                  'controls'
                ];
                let ix = index
              "
            >
              <h6 class="subheading">
                {{ "Medical Prescription" | translate }}
              </h6>
              <div formGroupName="{{ ix }}" class="medical-prescription-grid">
                <mat-form-field class="my-form-field" style="width: 50%">
                  <mat-label
                    >{{ "Medication Name" | translate }} {{ ix + 1 }}</mat-label
                  >
                  <input
                    matInput
                    placeholder=""
                    formControlName="name"
                    (change)="getNameUpdate($event, ix, 1)"
                  />
                </mat-form-field>
                <mat-form-field class="my-form-field" style="width: 100px">
                  <mat-label>{{ "Dosage" | translate }}</mat-label>
                  <input matInput placeholder="" formControlName="dosage" />
                </mat-form-field>
                <mat-form-field style="width: 150px" class="my-form-field">
                  <mat-label>{{
                    "international unit (s)" | translate
                  }}</mat-label>
                  <mat-select formControlName="unit">
                    <mat-option *ngFor="let u of Units" [value]="u._id">{{
                      u.unit[currentLanguage]
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field style="width: 150px" class="my-form-field">
                  <mat-label>{{ "Route (s)" | translate }}</mat-label>
                  <mat-select formControlName="routeOfAdministration">
                    <mat-option
                      *ngFor="let admin of adminstrations"
                      [value]="admin._id"
                    >
                      {{ admin.routeOfAdministration[currentLanguage] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="my-form-field" style="width: 100px">
                  <mat-label>{{ "Frequency" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    min="0"
                    placeholder=""
                    formControlName="frequency"
                    (keypress)="_keyPress($event)"
                  />
                </mat-form-field>
                <mat-form-field style="width: 100px" class="my-form-field">
                  <mat-label>{{ "units" | translate }}</mat-label>
                  <mat-select formControlName="frequencyTime">
                    <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                      t.time[currentLanguage]
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="my-form-field" style="width: 100px">
                  <mat-label>{{ "Duration" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    min="0"
                    placeholder=""
                    formControlName="duration"
                    (keypress)="_keyPress($event)"
                  />
                </mat-form-field>
                <mat-form-field style="width: 150px" class="my-form-field">
                  <mat-label>{{ "units" | translate }}</mat-label>
                  <mat-select formControlName="durationTime">
                    <mat-option *ngFor="let t of tablete" [value]="t._id">
                      {{ t.time[currentLanguage] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span
                  class="circle"
                  *ngIf="
                    X['controls'].alternative['controls'].length - 1 == -1 &&
                    isFollowUpEditEnabled
                  "
                  (click)="addY(ix, 1)"
                >
                  <mat-icon
                    ><img src="../../../assets/images/add.svg"
                  /></mat-icon>
                </span>
                <span
                  class="circle"
                  *ngIf="
                    isFollowUpEditEnabled &&
                    mpFollowUpForm['controls'].medicalPrescription['controls']
                      .length > 1
                  "
                  (click)="removeX(ix, X, 1)"
                >
                  <mat-icon>
                    <mat-icon
                      ><img
                        style="margin-left: 5px"
                        src="../../../assets/images/delete-all.svg"
                    /></mat-icon>
                  </mat-icon>
                </span>
  
                <mat-form-field style="width: 100%" appearance="outline">
                  <textarea
                    style="resize: none"
                    class="notes"
                    rows="5"
                    cols="117"
                    placeholder="{{ formControlPlaceholder | translate }}"
                    formControlName="notes"
                    matInput
                  ></textarea>
                </mat-form-field>
                <div formArrayName="alternative">
                  <div
                    *ngFor="
                      let Y of X['controls'].alternative['controls'];
                      let iy = index
                    "
                  >
                    <div
                      formGroupName="{{ iy }}"
                      class="medical-prescription-grid"
                    >
                      <mat-form-field class="my-form-field" style="width: 50%">
                        <mat-label
                          >{{ "Alternative" | translate }} -
                          {{ iy + 1 }}</mat-label
                        >
                        <input
                          matInput
                          placeholder=""
                          formControlName="name"
                          (change)="getAlternateNameUpdate($event, ix, iy, 1)"
                        />
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Dosage" | translate }}</mat-label>
                        <input matInput placeholder="" formControlName="dosage" />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{
                          "international unit (s)" | translate
                        }}</mat-label>
                        <mat-select formControlName="unit">
                          <mat-option *ngFor="let u of Units" [value]="u._id">
                            {{ u.unit[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "Route (s)" | translate }}</mat-label>
                        <mat-select formControlName="routeOfAdministration">
                          <mat-option
                            *ngFor="let admin of adminstrations"
                            [value]="admin._id"
                          >
                            {{ admin.routeOfAdministration[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Frequency" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="frequency"
                          (keypress)="_keyPress($event)"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="frequencyTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">
                            {{ t.time[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Duration" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="duration"
                          (keypress)="_keyPress($event)"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="durationTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">
                            {{ t.time[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <span
                        class="circle"
                        *ngIf="
                          isFollowUpEditEnabled &&
                          X['controls'].alternative['controls'].length - 1 == iy
                        "
                        (click)="addY(ix, 1)"
                      >
                        <mat-icon
                          ><img src="../../../assets/images/add.svg"
                        /></mat-icon>
                      </span>
                      <span
                        *ngIf="isFollowUpEditEnabled"
                        class="circle"
                        (click)="removeY(ix, iy, Y, 1)"
                      >
                        <mat-icon>
                          <mat-icon>
                            <img
                              style="margin-left: 5px"
                              src="../../../assets/images/delete-all.svg"
                            />
                          </mat-icon>
                        </mat-icon>
                      </span>
                      <mat-form-field style="width: 100%" appearance="outline">
                        <textarea
                          style="resize: none"
                          class="notes"
                          rows="5"
                          cols="117"
                          placeholder="{{ formControlPlaceholder | translate }}"
                          formControlName="notes"
                          matInput
                        ></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div
                  style="text-align: center"
                  *ngIf="
                    mpFollowUpForm['controls'].medicalPrescription['controls']
                      .length -
                      1 ==
                    ix
                  "
                >
                  <span
                    class="circle"
                    style="display: inline-flex"
                    (click)="addX(1)"
                    *ngIf="isFollowUpEditEnabled"
                  >
                    <mat-icon
                      ><img src="../../../assets/images/add.svg"
                    /></mat-icon>
                    <span style="padding-left: 10px; padding-top: 2px">{{
                      "Add Next" | translate
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- </mat-card> -->
      </div>
    }
    
    @if ( !hideFollowUpForm2 ) {
      <div formGroupName="followUpForm2">
        <!-- <mat-card class="cards"> -->
        <h6 class="subheading">{{ "Follow up Information" | translate }} 2</h6>
        <app-text-area
          controller="subjective"
          [data]="followUpForm2.subjective"
          label="{{ 'Subjective' | translate }}"
          (change)="updateFollowUp('subjective', $event, '2')"
          [edit]="isFollowUp2EditEnabled"
        ></app-text-area>
        <app-text-area
          controller="objective"
          [data]="followUpForm2.objective"
          label="{{ 'Objective' | translate }}"
          (change)="updateFollowUp('objective', $event, '2')"
          [edit]="isFollowUp2EditEnabled"
        ></app-text-area>
        <!--  <app-text-area
          controller="assesment"
          [data]="followUpForm2.assesment"
          label="{{ 'Assessment' | translate }}"
          (change)="updateFollowUp('assesment', $event, '2')"
          [edit]="isFollowUp2EditEnabled"
        ></app-text-area> -->
        <mat-form-field
          *ngIf="
            (!isFollowUpEditEnabled && followUpForm2.assesmentCie11.length > 0) ||
            isFollowUpEditEnabled
          "
          style="width: 100%"
          class="my-form-field"
        >
          <mat-label>{{ "Assessment" | translate }}</mat-label>
          <input
            type="text"
            matInput
            [disabled]="!isFollowUp2EditEnabled"
            [(ngModel)]="assessmentValue"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="
              onChangeFollowUp($event, followUpForm2.assesmentCie11)
            "
            [matAutocomplete]="diffAssessment2"
          />
  
          <mat-autocomplete #diffAssessment2="matAutocomplete">
            <mat-option
              (click)="addAssessment(diagnostic, followUpForm2.assesmentCie11)"
              *ngFor="let diagnostic of assessmentListFiltered"
              [value]="diagnostic"
              >{{ diagnostic.title }}</mat-option
            >
          </mat-autocomplete>
        </mat-form-field>
        <div
          *ngIf="
            (!isFollowUp2EditEnabled &&
              followUpForm2.assesmentCie11.length > 0) ||
            isFollowUp2EditEnabled
          "
          class="card-container"
        >
          <div class="card" *ngFor="let card of followUpForm2.assesmentCie11">
            <span>
              {{ transformTitle(card?.title ? card?.title : card?.cie11) }}</span
            >
            <mat-icon
              *ngIf="isFollowUp2EditEnabled"
              (click)="removeAssessmentFollowUp(card._id, 'followUpForm2')"
            >
              close</mat-icon
            >
          </div>
        </div>
        <mat-label
          style="color: #92a0a5"
          *ngIf="
            !isFollowUp2EditEnabled && followUpForm2.assesmentCie11.length < 1
          "
          >{{ "Assessment" | translate }}</mat-label
        >
        <div
          class="card-container"
          style="margin-top: 10px"
          *ngIf="
            !isFollowUp2EditEnabled && followUpForm2.assesmentCie11.length < 1
          "
          [innerHTML]="followUpForm2.assesment"
        ></div>
        <app-text-area
          controller="plan"
          [data]="followUpForm2.plan"
          label="{{ 'Plan' | translate }}"
          (change)="updateFollowUp('plan', $event, '2')"
          [edit]="isFollowUp2EditEnabled"
        ></app-text-area>
  
        <div
          *ngIf="!!visit.hasFollowUp && hideFollowUpForm3 && canCloseAttention"
        >
          <form formGroupName="closeAttentionForm">
            <div class="end-of-medical-care">
              <div class="completed-medical-care switch-config-button">
                <span *ngIf="!closedAttention" style="margin-right: 20px">{{
                  "Closing this attention" | translate
                }}</span>
                <span *ngIf="closedAttention">{{
                  "Completed medical care" | translate
                }}</span>
                <mat-slide-toggle
                  #slideCloseAttention
                  formControlName="closeAttention"
                  (change)="closeAttentionChange($event)"
                  id="slide-question"
                  class="test-call-toggle"
                >
                  <span
                    *ngIf="closeAttentionForm.enabled"
                    class="slide-question-label"
                    [ngClass]="{ checked: slideCloseAttention.checked }"
                  >
                    {{ (slideCloseAttention.checked ? "Yes" : "No") | translate }}
                  </span>
                </mat-slide-toggle>
              </div>
  
              <div *ngIf="slideCloseAttention.checked" class="closure-reason">
                <div class="left-side">
                  <mat-form-field class="select-reason">
                    <mat-label
                      >{{ "Query closure reason" | translate }} *</mat-label
                    >
                    <mat-select
                      formControlName="closureReason"
                      class="closure-reason-select"
                      (selectionChange)="closureReasonChange($event)"
                      (opened)="closureReasonOpened()"
                    >
                      <mat-option
                        *ngFor="let closureReason of closureReasons"
                        [value]="closureReason._id"
                        class="multiline-mat-option reason-option"
                      >
                        <div
                          *ngIf="
                            closureReason.isOther;
                            then isOtherBlock;
                            else isNotOtherBlock
                          "
                        ></div>
  
                        <ng-template #isOtherBlock>
                          <span
                            *ngIf="
                              !closureReasonSelectIsOpen &&
                                !isOtherClosureReason &&
                                !!closeAttentionForm.get('otherClosureReason')
                                  .value;
                              else isNotOtherClosureReason
                            "
                          >
                            {{
                              closeAttentionForm.get("otherClosureReason").value
                            }}
                          </span>
  
                          <ng-template #isNotOtherClosureReason>{{
                            closureReason.closureReason[currentLanguage]
                          }}</ng-template>
                        </ng-template>
  
                        <ng-template #isNotOtherBlock>
                          {{ closureReason.closureReason[currentLanguage] }}
                        </ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
  
                <div class="right-side" *ngIf="isOtherClosureReason">
                  <mat-form-field>
                    <mat-label>{{ "Reason for closure" | translate }}</mat-label>
                    <input
                      matInput
                      formControlName="otherClosureReason"
                      maxlength="35"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
  
        <form [formGroup]="mpFollowUpForm2">
          <div formArrayName="medicalPrescription">
            <div
              *ngFor="
                let X of mpFollowUpForm2['controls'].medicalPrescription[
                  'controls'
                ];
                let ix = index
              "
            >
              <h6 class="subheading">{{ "Medical Prescription" | translate }}</h6>
              <div formGroupName="{{ ix }}" class="medical-prescription-grid">
                <mat-form-field class="my-form-field" style="width: 50%">
                  <mat-label
                    >{{ "Medication Name" | translate }} {{ ix + 1 }}</mat-label
                  >
                  <input
                    matInput
                    placeholder=""
                    formControlName="name"
                    (change)="getNameUpdate($event, ix, 2)"
                  />
                </mat-form-field>
                <mat-form-field class="my-form-field" style="width: 100px">
                  <mat-label>{{ "Dosage" | translate }}</mat-label>
                  <input matInput placeholder="" formControlName="dosage" />
                </mat-form-field>
                <mat-form-field style="width: 150px" class="my-form-field">
                  <mat-label>{{
                    "international unit (s)" | translate
                  }}</mat-label>
                  <mat-select formControlName="unit">
                    <mat-option *ngFor="let u of Units" [value]="u._id">{{
                      u.unit[currentLanguage]
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field style="width: 150px" class="my-form-field">
                  <mat-label>{{ "Route (s)" | translate }}</mat-label>
                  <mat-select formControlName="routeOfAdministration">
                    <mat-option
                      *ngFor="let admin of adminstrations"
                      [value]="admin._id"
                    >
                      {{ admin.routeOfAdministration[currentLanguage] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="my-form-field" style="width: 100px">
                  <mat-label>{{ "Frequency" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    min="0"
                    placeholder=""
                    formControlName="frequency"
                    (keypress)="_keyPress($event)"
                  />
                </mat-form-field>
                <mat-form-field style="width: 100px" class="my-form-field">
                  <mat-label>{{ "units" | translate }}</mat-label>
                  <mat-select formControlName="frequencyTime">
                    <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                      t.time[currentLanguage]
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="my-form-field" style="width: 100px">
                  <mat-label>{{ "Duration" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    min="0"
                    placeholder=""
                    formControlName="duration"
                    (keypress)="_keyPress($event)"
                  />
                </mat-form-field>
                <mat-form-field style="width: 150px" class="my-form-field">
                  <mat-label>{{ "units" | translate }}</mat-label>
                  <mat-select formControlName="durationTime">
                    <mat-option *ngFor="let t of tablete" [value]="t._id">
                      {{ t.time[currentLanguage] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span
                  class="circle"
                  *ngIf="
                    X['controls'].alternative['controls'].length - 1 == -1 &&
                    isFollowUp2EditEnabled
                  "
                  (click)="addY(ix, 2)"
                >
                  <mat-icon
                    ><img src="../../../assets/images/add.svg"
                  /></mat-icon>
                </span>
                <span
                  class="circle"
                  *ngIf="
                    isFollowUp2EditEnabled &&
                    mpFollowUpForm2['controls'].medicalPrescription['controls']
                      .length > 1
                  "
                  (click)="removeX(ix, X, 2)"
                >
                  <mat-icon>
                    <mat-icon
                      ><img
                        style="margin-left: 5px"
                        src="../../../assets/images/delete-all.svg"
                    /></mat-icon>
                  </mat-icon>
                </span>
  
                <mat-form-field style="width: 100%" appearance="outline">
                  <textarea
                    style="resize: none"
                    class="notes"
                    rows="5"
                    cols="117"
                    placeholder="{{ formControlPlaceholder | translate }}"
                    formControlName="notes"
                    matInput
                  ></textarea>
                </mat-form-field>
                <div formArrayName="alternative">
                  <div
                    *ngFor="
                      let Y of X['controls'].alternative['controls'];
                      let iy = index
                    "
                  >
                    <div
                      formGroupName="{{ iy }}"
                      class="medical-prescription-grid"
                    >
                      <mat-form-field class="my-form-field" style="width: 50%">
                        <mat-label
                          >{{ "Alternative" | translate }} -
                          {{ iy + 1 }}</mat-label
                        >
                        <input
                          matInput
                          placeholder=""
                          formControlName="name"
                          (change)="getAlternateNameUpdate($event, ix, iy, 2)"
                        />
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Dosage" | translate }}</mat-label>
                        <input matInput placeholder="" formControlName="dosage" />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{
                          "international unit (s)" | translate
                        }}</mat-label>
                        <mat-select formControlName="unit">
                          <mat-option *ngFor="let u of Units" [value]="u._id">
                            {{ u.unit[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "Route (s)" | translate }}</mat-label>
                        <mat-select formControlName="routeOfAdministration">
                          <mat-option
                            *ngFor="let admin of adminstrations"
                            [value]="admin._id"
                          >
                            {{ admin.routeOfAdministration[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Frequency" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="frequency"
                          (keypress)="_keyPress($event)"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="frequencyTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">
                            {{ t.time[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Duration" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="duration"
                          (keypress)="_keyPress($event)"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="durationTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">
                            {{ t.time[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <span
                        class="circle"
                        *ngIf="
                          isFollowUp2EditEnabled &&
                          X['controls'].alternative['controls'].length - 1 == iy
                        "
                        (click)="addY(ix, 2)"
                      >
                        <mat-icon
                          ><img src="../../../assets/images/add.svg"
                        /></mat-icon>
                      </span>
                      <span
                        *ngIf="isFollowUp2EditEnabled"
                        class="circle"
                        (click)="removeY(ix, iy, Y, 2)"
                      >
                        <mat-icon>
                          <mat-icon>
                            <img
                              style="margin-left: 5px"
                              src="../../../assets/images/delete-all.svg"
                            />
                          </mat-icon>
                        </mat-icon>
                      </span>
                      <mat-form-field style="width: 100%" appearance="outline">
                        <textarea
                          style="resize: none"
                          class="notes"
                          rows="5"
                          cols="117"
                          placeholder="{{ formControlPlaceholder | translate }}"
                          formControlName="notes"
                          matInput
                        ></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div
                  style="text-align: center"
                  *ngIf="
                    mpFollowUpForm2['controls'].medicalPrescription['controls']
                      .length -
                      1 ==
                    ix
                  "
                >
                  <span
                    class="circle"
                    style="display: inline-flex"
                    (click)="addX(2)"
                    *ngIf="isFollowUp2EditEnabled"
                  >
                    <mat-icon
                      ><img src="../../../assets/images/add.svg"
                    /></mat-icon>
                    <span style="padding-left: 10px; padding-top: 2px">{{
                      "Add Next" | translate
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- </mat-card> -->
      </div>
    }

    @if ( !hideFollowUpForm3 ) {
      <div formGroupName="followUpForm3">
        <!-- <mat-card class="cards"> -->
        <h6 class="subheading">{{ "Follow up Information" | translate }} 3</h6>
        <app-text-area
          controller="subjective"
          [data]="followUpForm3.subjective"
          label="{{ 'Subjective' | translate }}"
          (change)="updateFollowUp('subjective', $event, '3')"
          [edit]="isFollowUp3EditEnabled"
        ></app-text-area>
        <app-text-area
          controller="objective"
          [data]="followUpForm3.objective"
          label="{{ 'Objective' | translate }}"
          (change)="updateFollowUp('objective', $event, '3')"
          [edit]="isFollowUp3EditEnabled"
        ></app-text-area>
        <!--       <app-text-area
          controller="assesment"
          [data]="followUpForm3.assesment"
          label="{{ 'Assessment' | translate }}"
          (change)="updateFollowUp('assesment', $event, '3')"
          [edit]="isFollowUp3EditEnabled"
        ></app-text-area> -->
        <mat-form-field
          *ngIf="
            (!isFollowUpEditEnabled && followUpForm3.assesmentCie11.length > 0) ||
            isFollowUpEditEnabled
          "
          style="width: 100%"
          class="my-form-field"
        >
          <mat-label>{{ "Assessment" | translate }}</mat-label>
          <input
            type="text"
            matInput
            [disabled]="!isFollowUp3EditEnabled"
            [(ngModel)]="assessmentValue"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="
              onChangeFollowUp($event, followUpForm3.assesmentCie11)
            "
            [matAutocomplete]="diffAssessment3"
          />
  
          <mat-autocomplete #diffAssessment3="matAutocomplete">
            <mat-option
              (click)="addAssessment(diagnostic, followUpForm3.assesmentCie11)"
              *ngFor="let diagnostic of assessmentListFiltered"
              [value]="diagnostic"
              >{{ diagnostic.title }}</mat-option
            >
          </mat-autocomplete>
        </mat-form-field>
        <div
          *ngIf="
            (!isFollowUp2EditEnabled &&
              followUpForm3.assesmentCie11.length > 0) ||
            isFollowUp3EditEnabled
          "
          class="card-container"
        >
          <div class="card" *ngFor="let card of followUpForm3.assesmentCie11">
            <span>
              {{ transformTitle(card?.title ? card?.title : card?.cie11) }}</span
            >
            <mat-icon
              *ngIf="isFollowUp3EditEnabled"
              (click)="removeAssessmentFollowUp(card._id, 'followUpForm3')"
            >
              close</mat-icon
            >
          </div>
        </div>
        <mat-label
          style="color: #92a0a5"
          *ngIf="
            !isFollowUp3EditEnabled && followUpForm3.assesmentCie11.length < 1
          "
          >{{ "Assessment" | translate }}</mat-label
        >
        <div
          class="card-container"
          style="margin-top: 10px"
          *ngIf="
            !isFollowUp3EditEnabled && followUpForm3.assesmentCie11.length < 1
          "
          [innerHTML]="followUpForm3.assesment"
        ></div>
        <!-- ///////////////////////////// -->
        <app-text-area
          controller="plan"
          [data]="followUpForm3.plan"
          label="{{ 'Plan' | translate }}"
          (change)="updateFollowUp('plan', $event, '3')"
          [edit]="isFollowUp3EditEnabled"
        ></app-text-area>
  
        <div *ngIf="!!visit.hasFollowUp && canCloseAttention">
          <form formGroupName="closeAttentionForm">
            <div class="end-of-medical-care">
              <div class="completed-medical-care switch-config-button">
                <span *ngIf="!closedAttention" style="margin-right: 20px">{{
                  "Closing this attention" | translate
                }}</span>
                <span *ngIf="closedAttention">{{
                  "Completed medical care" | translate
                }}</span>
                <mat-slide-toggle
                  #slideCloseAttention
                  formControlName="closeAttention"
                  (change)="closeAttentionChange($event)"
                  id="slide-question"
                  class="test-call-toggle"
                >
                  <span
                    *ngIf="closeAttentionForm.enabled"
                    class="slide-question-label"
                    [ngClass]="{ checked: slideCloseAttention.checked }"
                  >
                    {{ (slideCloseAttention.checked ? "Yes" : "No") | translate }}
                  </span>
                </mat-slide-toggle>
              </div>
  
              <div *ngIf="slideCloseAttention.checked" class="closure-reason">
                <div class="left-side">
                  <mat-form-field class="select-reason">
                    <mat-label
                      >{{ "Query closure reason" | translate }} *</mat-label
                    >
                    <mat-select
                      formControlName="closureReason"
                      class="closure-reason-select"
                      (selectionChange)="closureReasonChange($event)"
                      (opened)="closureReasonOpened()"
                    >
                      <mat-option
                        *ngFor="let closureReason of closureReasons"
                        [value]="closureReason._id"
                        class="multiline-mat-option reason-option"
                      >
                        <div
                          *ngIf="
                            closureReason.isOther;
                            then isOtherBlock;
                            else isNotOtherBlock
                          "
                        ></div>
  
                        <ng-template #isOtherBlock>
                          <span
                            *ngIf="
                              !closureReasonSelectIsOpen &&
                                !isOtherClosureReason &&
                                !!closeAttentionForm.get('otherClosureReason')
                                  .value;
                              else isNotOtherClosureReason
                            "
                          >
                            {{
                              closeAttentionForm.get("otherClosureReason").value
                            }}
                          </span>
  
                          <ng-template #isNotOtherClosureReason>{{
                            closureReason.closureReason[currentLanguage]
                          }}</ng-template>
                        </ng-template>
  
                        <ng-template #isNotOtherBlock>
                          {{ closureReason.closureReason[currentLanguage] }}
                        </ng-template>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
  
                <div class="right-side" *ngIf="isOtherClosureReason">
                  <mat-form-field>
                    <mat-label>{{ "Reason for closure" | translate }}</mat-label>
                    <input
                      matInput
                      formControlName="otherClosureReason"
                      maxlength="35"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
  
        <form [formGroup]="mpFollowUpForm3">
          <div formArrayName="medicalPrescription">
            <div
              *ngFor="
                let X of mpFollowUpForm3['controls'].medicalPrescription[
                  'controls'
                ];
                let ix = index
              "
            >
              <h6 class="subheading">{{ "Medical Prescription" | translate }}</h6>
              <div formGroupName="{{ ix }}" class="medical-prescription-grid">
                <mat-form-field class="my-form-field" style="width: 50%">
                  <mat-label
                    >{{ "Medication Name" | translate }} {{ ix + 1 }}</mat-label
                  >
                  <input
                    matInput
                    placeholder=""
                    formControlName="name"
                    (change)="getNameUpdate($event, ix, 3)"
                  />
                </mat-form-field>
                <mat-form-field class="my-form-field" style="width: 100px">
                  <mat-label>{{ "Dosage" | translate }}</mat-label>
                  <input matInput placeholder="" formControlName="dosage" />
                </mat-form-field>
                <mat-form-field style="width: 150px" class="my-form-field">
                  <mat-label>{{
                    "international unit (s)" | translate
                  }}</mat-label>
                  <mat-select formControlName="unit">
                    <mat-option *ngFor="let u of Units" [value]="u._id">{{
                      u.unit[currentLanguage]
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field style="width: 150px" class="my-form-field">
                  <mat-label>{{ "Route (s)" | translate }}</mat-label>
                  <mat-select formControlName="routeOfAdministration">
                    <mat-option
                      *ngFor="let admin of adminstrations"
                      [value]="admin._id"
                    >
                      {{ admin.routeOfAdministration[currentLanguage] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="my-form-field" style="width: 100px">
                  <mat-label>{{ "Frequency" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    min="0"
                    placeholder=""
                    formControlName="frequency"
                    (keypress)="_keyPress($event)"
                  />
                </mat-form-field>
                <mat-form-field style="width: 100px" class="my-form-field">
                  <mat-label>{{ "units" | translate }}</mat-label>
                  <mat-select formControlName="frequencyTime">
                    <mat-option *ngFor="let t of tablete" [value]="t._id">{{
                      t.time[currentLanguage]
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="my-form-field" style="width: 100px">
                  <mat-label>{{ "Duration" | translate }}</mat-label>
                  <input
                    type="number"
                    matInput
                    min="0"
                    placeholder=""
                    formControlName="duration"
                    (keypress)="_keyPress($event)"
                  />
                </mat-form-field>
                <mat-form-field style="width: 150px" class="my-form-field">
                  <mat-label>{{ "units" | translate }}</mat-label>
                  <mat-select formControlName="durationTime">
                    <mat-option *ngFor="let t of tablete" [value]="t._id">
                      {{ t.time[currentLanguage] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span
                  class="circle"
                  *ngIf="
                    X['controls'].alternative['controls'].length - 1 == -1 &&
                    isFollowUp3EditEnabled
                  "
                  (click)="addY(ix, 3)"
                >
                  <mat-icon
                    ><img src="../../../assets/images/add.svg"
                  /></mat-icon>
                </span>
                <span
                  class="circle"
                  *ngIf="
                    isFollowUp3EditEnabled &&
                    mpFollowUpForm3['controls'].medicalPrescription['controls']
                      .length > 1
                  "
                  (click)="removeX(ix, X, 3)"
                >
                  <mat-icon>
                    <mat-icon
                      ><img
                        style="margin-left: 5px"
                        src="../../../assets/images/delete-all.svg"
                    /></mat-icon>
                  </mat-icon>
                </span>
  
                <mat-form-field style="width: 100%" appearance="outline">
                  <textarea
                    style="resize: none"
                    class="notes"
                    rows="5"
                    cols="117"
                    placeholder="{{ formControlPlaceholder | translate }}"
                    formControlName="notes"
                    matInput
                  ></textarea>
                </mat-form-field>
                <div formArrayName="alternative">
                  <div
                    *ngFor="
                      let Y of X['controls'].alternative['controls'];
                      let iy = index
                    "
                  >
                    <div
                      formGroupName="{{ iy }}"
                      class="medical-prescription-grid"
                    >
                      <mat-form-field class="my-form-field" style="width: 50%">
                        <mat-label
                          >{{ "Alternative" | translate }} -
                          {{ iy + 1 }}</mat-label
                        >
                        <input
                          matInput
                          placeholder=""
                          formControlName="name"
                          (change)="getAlternateNameUpdate($event, ix, iy, 3)"
                        />
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Dosage" | translate }}</mat-label>
                        <input matInput placeholder="" formControlName="dosage" />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{
                          "international unit (s)" | translate
                        }}</mat-label>
                        <mat-select formControlName="unit">
                          <mat-option *ngFor="let u of Units" [value]="u._id">
                            {{ u.unit[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "Route (s)" | translate }}</mat-label>
                        <mat-select formControlName="routeOfAdministration">
                          <mat-option
                            *ngFor="let admin of adminstrations"
                            [value]="admin._id"
                          >
                            {{ admin.routeOfAdministration[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Frequency" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="frequency"
                          (keypress)="_keyPress($event)"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="frequencyTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">
                            {{ t.time[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="my-form-field" style="width: 100px">
                        <mat-label>{{ "Duration" | translate }}</mat-label>
                        <input
                          type="number"
                          matInput
                          min="0"
                          placeholder=""
                          formControlName="duration"
                          (keypress)="_keyPress($event)"
                        />
                      </mat-form-field>
                      <mat-form-field style="width: 100px" class="my-form-field">
                        <mat-label>{{ "units" | translate }}</mat-label>
                        <mat-select formControlName="durationTime">
                          <mat-option *ngFor="let t of tablete" [value]="t._id">
                            {{ t.time[currentLanguage] }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <span
                        class="circle"
                        *ngIf="
                          isFollowUp3EditEnabled &&
                          X['controls'].alternative['controls'].length - 1 == iy
                        "
                        (click)="addY(ix, 3)"
                      >
                        <mat-icon
                          ><img src="../../../assets/images/add.svg"
                        /></mat-icon>
                      </span>
                      <span
                        *ngIf="isFollowUp3EditEnabled"
                        class="circle"
                        (click)="removeY(ix, iy, Y, 3)"
                      >
                        <mat-icon>
                          <mat-icon>
                            <img
                              style="margin-left: 5px"
                              src="../../../assets/images/delete-all.svg"
                            />
                          </mat-icon>
                        </mat-icon>
                      </span>
                      <mat-form-field style="width: 100%" appearance="outline">
                        <textarea
                          style="resize: none"
                          class="notes"
                          rows="5"
                          cols="117"
                          placeholder="{{ formControlPlaceholder | translate }}"
                          formControlName="notes"
                          matInput
                        ></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div
                  style="text-align: center"
                  *ngIf="
                    mpFollowUpForm3['controls'].medicalPrescription['controls']
                      .length -
                      1 ==
                    ix
                  "
                >
                  <span
                    class="circle"
                    style="display: inline-flex"
                    (click)="addX(3)"
                    *ngIf="isFollowUp3EditEnabled"
                  >
                    <mat-icon
                      ><img src="../../../assets/images/add.svg"
                    /></mat-icon>
                    <span style="padding-left: 10px; padding-top: 2px">{{
                      "Add Next" | translate
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- </mat-card> -->
      </div>
    }
    <!-- Follow up Info End -->
  </form>
  <div *ngIf="showScheduleFollowUpAppointmentFormBelow">
    <br />
    <br />
    <mat-divider></mat-divider>
    <form
      [formGroup]="scheduleFollowUpAppointmentForm"
      (ngSubmit)="scheduleFollowUpAppointmentFormSubmit()"
      *ngIf="showScheduleFollowUpAppointmentFormBelow"
    >
      <div class="schedule-follow-up-appointment">
        <div class="schedule-follow-up-appointment-left">
          <div
            class="schedule-follow-up"
            (click)="openScheduleFollowUpConsultation()"
          >
            <mat-icon> add_call </mat-icon>
            <div class="heading">
              {{ "Schedule follow-up" | translate }}
            </div>
          </div>

          <mat-icon
            class="info"
            matTooltip="{{
              'Until 12:00 am you can edit the tracking date' | translate
            }}."
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'message-tooltip'"
            >info_outlined</mat-icon
          >
        </div>

        <div class="schedule-follow-up-appointment-right">
          <div class="follow-up-date">
            <div class="heading">
              {{ "Follow up date" | translate }}
            </div>

            <span *ngIf="!scheduleDate">{{ "Not scheduled" | translate }}</span>
            <span *ngIf="scheduleDate">{{
              scheduleDate | date: "dd/MM/yyyy"
            }}</span>
          </div>

          <button
            type="submit"
            style="
              background: none;
              cursor: pointer;
              color: #65a7f6;
              border: none;
            "
            class="update-btn"
          >
            <img
              class="icon"
              style="vertical-align: bottom"
              src="assets/images/save.svg"
            />
            <span style="vertical-align: super">{{
              "Update" | translate
            }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- <h5>Field Values</h5>
<pre style="font-size: 15px;">{{ form.value | json }}</pre> -->
