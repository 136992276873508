import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Credentials, CredentialsService } from "./credentials.service";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

export interface LoginContext {
  userName: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 *
 */
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private router: Router,
  ) {}

  /**
   * Authenticates the user.
   * @return The user credentials.
   */
  login(loginDetails: any, hashedPassword: string): Observable<Credentials> {
    let data = { email: loginDetails.email, password: hashedPassword };
    return this.http.post<any>("/doctors/login", data).pipe(
      map((res) => {
        const userData = {
          username: loginDetails.email,
          token: res.data.accessToken,
          userDetails: res.data,
          qbDetails: res.meta.quickblox,
          fileUrl: res.meta.fileUrl,
          phoneNumber: res.data.phoneNumber,
          countryCode: res.data.countryCode,
          role: res.data.user,
        };

        this.credentialsService.setCredentials(userData, loginDetails.remember);

        return { ...res };
      }),
    );
  }

  /**
   * sending requeust for forgot password.
   */
  forgotPassword(data: string): Observable<Credentials> {
    return this.http.patch<any>("/doctors/forgot-password", data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  /**
   * sending requeust for change password.
   */
  resetPassword(password: string, oldPassword?: string) {
    let data = { password: password, oldPassword: oldPassword };
    return this.http.patch<any>("/doctors/change-password", data).pipe(
      map((res) => {
        return res;
      }),
    );
  }
  resetPasswordAgent(password: string, oldPassword?: string) {
    let data = { password: password, oldPassword: oldPassword };
    return this.http.patch<any>("/agents/change-password", data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  /**
   * sending requeust to verify the code.
   */
  codeVerification(code: any) {
    return this.http.post<any>(`/doctors/verify/${code}`, {}).pipe(
      map((res) => {
        console.log(res);
        if ( !res.data?.accessToken ) {
          this.router.navigate(["/login"]);
          throw new Error("Codigo de verificacion invalido");
        }
        const userData = {
          username: res.data.email,
          token: res.data.accessToken,
          userDetails: res.data,
          qbDetails: res.meta.quickblox,
          fileUrl: res.meta.fileUrl,
          phoneNumber: res.data.phoneNumber,
          countryCode: res.data.countryCode,
          role: res.data.user,
        };
        this.credentialsService.setCredentials(userData, false);
        return res;
      }),
    );
  }
  codeVerificationAgent(code: any) {
    return this.http.post<any>(`/agents/verify/${code}`, {}).pipe(
      map((res) => {
        
        if ( !res.data?.accessToken ) {
          this.router.navigate(["/login"]);
          throw new Error("Codigo de verificacion invalido");
        }

        this.credentialsService.setCredentials({
          username: res.data.email,
          token: res.data.accessToken,
          qbDetails: res.meta.quickblox,
          userDetails: res.data,
          fileUrl: res.meta.fileUrl,
          phoneNumber: res.data.phoneNumber,
          countryCode: res.data.countryCode,
          role: "agent",
        }, false);
        return res;
      }),
    );
  }
  /**
   * Request 2FA for authentication
   */
  request2FA(email: string, useSMS: boolean): Observable<any> {
    let data = { email: email, sendSms: useSMS };
    return this.http.post<any>(`/${this.getRolePath()}/send-twofa`, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  /**
   * Request send again 2FA for authentication to same user
   */
  resend2FA(email: string, useSMS: boolean): Observable<any> {
    let data = { email: email, sendSms: useSMS };
    return this.http.patch<any>(`/${this.getRolePath()}/resend-otp`, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  /**
   * Validate 2FA and authenticate user
   * @return The user credentials.
   */
  validate2FA(
    verificationCode: string,
    useEquipment: number,
  ): Observable<Credentials> {
    let data = {
      verificationCode: verificationCode,
      useEquipment: useEquipment,
    };
    return this.http
      .post<any>(`/${this.getRolePath()}/verify-twofa`, data)
      .pipe(
        map((res) => {
          const userData = {
            username: res.data.email,
            token: res.data.accessToken,
            userDetails: res.data,
            qbDetails: res.meta.quickblox,
            fileUrl: res.meta.fileUrl,
            phoneNumber: res.data.phoneNumber,
            countryCode: res.data.countryCode,
            role: this.credentialsService.credentials.role,
          };
          this.credentialsService.setCredentials(userData, false);
          return res;
        }),
      );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    return this.http.post<any>(`/${this.getRolePath()}/logout`, {}).pipe(
      map((res) => {
        this.credentialsService.setCredentials();
        return true;
      }),
    );
  }

  getRolePath() {
    const role = this.credentialsService.credentials.role;
    if (role === "agent") {
      return "agents";
    } else {
      return "doctors";
    }
  }
}
