import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "../dashboard.service";
import moment from "moment";
import { HttpErrorResponse } from "@angular/common/http";
import { CredentialsService } from "src/app/core/credentials.service";
import { SharedService } from "src/app/shared/shared.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { SubscriptionDetails } from "src/app/core/subscriptions.spec";
import { Subscription } from "rxjs";
import { ModalService } from "../modal.service";
import { MatDialog } from "@angular/material/dialog";
import { ConsultationOriginComponent } from "../consultation-origin-modal/consultation-origin.component";
import { AffiliationsModalComponent } from "./affiliations-modal/affiliations-modal.component";

export class PageParams {
  patient: string;
  status: number;
  size: number;
  skip: number;
}

export enum IsPregnant {
  YES = 1,
  NO = 2,
  NOT_SURE = 3,
}

export enum CommunicationMode {
  PHONE = 1,
  VIDEO = 2,
  CHAT = 3,
}

export enum chiefComplaintCallByDoctor {
  otherChiefComplaint = "Solicitar llamada",
}

export interface ConsultationList {
  data: Consultation[];
  meta: Meta;
}

export interface Consultation {
  _id:                      string;
  document:                 any[];
  status:                   number;
  action:                   string[];
  paraclinicalPrescription: any[];
  medicalPrescription:      MedicalPrescription[];
  parent:                   string;
  createdAt:                Date;
  updatedAt:                Date;
  chiefComplaint:           ChiefComplaint;
  communicationMode:        number;
  date:                     Date;
  utcOffset:                number;
  visitForm:                string;
  hasFollowUp:              boolean;
  followUpDate:             Date;
  followUpConsultation:     FollowUpConsultation[];
  waitingTime:              number;
  consultationTime:         number;
  patient:                  Patient;
  doctor:                   DatumDoctor;
  hour:                     string;
  appointmentType:          number;
  appointmentStatus:        number;
  followUpConsultation2:    FollowUpConsultation[];
  followUpConsultation3:    FollowUpConsultation[];
  followUpDate2?:           Date;
  followUpDate3?:           Date;
}

export interface ChiefComplaint {
  id:             string;
  chiefComplaint: SpecialityClass;
  other?:         string;
}

export interface SpecialityClass {
  en: string;
  es: string;
}

export interface DatumDoctor {
  _id:                  string;
  firstName:            string;
  lastName:             string;
  email:                string;
  gender:               number;
  cmNumber:             string;
  countryCode:          string;
  identificationNumber: string;
  mppsNumber:           string;
  phoneNumber:          string;
  speciality:           Speciality[];
  subSpeciality:        SubSpeciality[];
}

export interface Speciality {
  id:         string;
  speciality: SpecialityClass;
}

export interface SubSpeciality {
  id:            string;
  subSpeciality: SpecialityClass;
}

export interface FollowUpConsultation {
  _id:    string;
  date:   Date;
  doctor: DoctorElement[];
}

export interface DoctorElement {
  _id:       string;
  firstName: string;
  lastName:  string;
  gender:    number;
}

export interface MedicalPrescription {
  _id:                   string;
  consultation:          string;
  patient:               string;
  parent:                string;
  doctor:                string;
  status:                number;
  name:                  string;
  dosage:                string;
  unit:                  Unit;
  routeOfAdministration: RouteOfAdministration;
  frequency:             number;
  frequencyTime:         Time;
  duration:              number;
  durationTime:          Time;
  isAlternative:         boolean;
  alternative:           any[];
  createdAt:             Date;
  updatedAt:             Date;
}

export interface Time {
  id:   string;
  time: SpecialityClass;
}

export interface RouteOfAdministration {
  id:                    string;
  routeOfAdministration: SpecialityClass;
}

export interface Unit {
  id:   string;
  unit: SpecialityClass;
}

export interface Patient {
  _id:                   string;
  firstName:             string;
  lastName:              string;
  gender:                number;
  dateOfBirth:           Date;
  identificationNumber:  string;
  countryCode:           string;
  phoneNumber:           string;
  email:                 string;
  password:              string;
  verificationCode:      null;
  resetStatus:           number;
  status:                number;
  visitPWA:              boolean;
  callMade:              boolean;
  isFreeTrialUsed:       boolean;
  tryAndBuyUsed:         boolean;
  tryAndBuyOrigin:       number;
  tryAndBuyAccepted:     boolean;
  tryAndBuyCountDecline: number;
  shippingCounterEmail:  number;
  shippingCounterSms:    number;
  typeRegister:          number;
  canSubscribe:          boolean;
  createdAt:             Date;
  updatedAt:             Date;
  device:                Device;
  preference:            Preference;
  gcm:                   Gcm;
  agreementAccepted:     AgreementAccepted;
  quickbloxId:           string;
  quickbloxInfo:         QuickbloxInfo;
  subscription:          SubscriptionMain;
}

export interface AgreementAccepted {
  termsAndConditionsAccepted:   boolean;
  termsAndConditionsAcceptedAt: Date;
  privacyPolicyAccepted:        boolean;
  privacyPolicyAcceptedAt:      Date;
}

export interface Device {
  accessToken:  string;
  refreshToken: string;
  type:         number;
}

export interface Gcm {
  endpoint:       string;
  expirationTime: null;
  keys:           Keys;
}

export interface Keys {
  p256dh: string;
  auth:   string;
}

export interface Preference {
  pushNotification: boolean;
  email:            boolean;
}

export interface QuickbloxInfo {
  id:               number;
  full_name:        string;
  email:            null;
  login:            string;
  phone:            null;
  website:          null;
  created_at:       Date;
  updated_at:       Date;
  last_request_at:  null;
  external_user_id: null;
  facebook_id:      null;
  blob_id:          null;
  custom_data:      null;
  age_over16:       boolean;
  parents_contacts: string;
  user_tags:        null;
}

export interface SubscriptionMain {
  status:   number;
  provider: Provider;
}

export interface Provider {
  type:             number;
  provider:         string;
  subscriptionInfo: SubscriptionInfo;
}

export interface SubscriptionInfo {
  serviceId:        number;
  name:             string;
  status:           string;
  subscriptionDate: Date;
  price:            number;
  operator:         number;
  country:          string;
  periodicity:      number;
}

export interface Meta {
  fileUrl:               string;
  videoRecordingFileUrl: string;
  total:                 number;
}

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild("medicalhistory", { read: ElementRef })
  medicalHistoryElement: ElementRef;
  @ViewChild("attachments", { read: ElementRef })
  attachmentsElement: ElementRef;
  @ViewChild("medication", { read: ElementRef }) medicationElement: ElementRef;
  @ViewChild("visithistory", { read: ElementRef })
  visitHistoryElement: ElementRef;

  @ViewChild("componentContainer", { static: true })
  compContainerElement: ElementRef;
  public currentActive = 0;
  public visitSummaryOffset: Number = null;
  public medicalHistoryOffset: Number = null;
  public attachmentsOffset: Number = null;
  public medicationOffset: Number = null;
  public visitHistoryOffset: Number = null;
  public mainScrollOffset: number = null;
  pageParams: Partial<PageParams> = { size: 3, skip: 0 };
  visitHistoryDetails = [];
  patientId: string;
  patientDetials: any;
  medicalHistoryDetails: any;
  age: number;
  bmi: any;
  displayBMI: any;
  loading: boolean = true;
  activeState = "Medical History";
  fileUrl: string;
  countryCode: string;
  phoneNumber: string;
  currentSection: string = "visitHistory";

  subscriptionsSubscription: Subscription;
  subscriptionsElements: SubscriptionDetails[] = [];
  showOverlayAffiliationsView = false;

  otherChiefComplaintId: string = "";

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private credService: CredentialsService,
    private dashboardService: DashboardService,
    private translationService: TranslateService,
    private sharedService: SharedService,
    private modalService: ModalService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      console.log(this.patientId, params.patient);
      
      this.patientId = params.patient;
      this.getPatientDetails(this.patientId);
      this.getConsultationList();
      this.getSubscriptionElements();
    });

    this.fileUrl = this.credService.credentials.fileUrl + "/";

    this.subscriptions.add(
      this.dashboardService.getChiefComplaintList().subscribe((data) => {
        if (data) {
          const idx = data.findIndex((x) => x.isOther);
          if (idx !== 1) {
            this.otherChiefComplaintId = data[idx]._id;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setStateAsActive(link) {
    this.activeState = link.name;
  }

  scroll(el: HTMLElement, active: number) {
    el.scrollIntoView();
    this.currentActive = active;
  }

  divScroll(e) {
    this.mainScrollOffset = e.target.scrollTop;
    if (
      this.mainScrollOffset >= this.visitHistoryOffset?.valueOf() &&
      this.mainScrollOffset < this.medicalHistoryOffset?.valueOf()
    ) {
      this.currentActive = 1;
    } else if (
      this.mainScrollOffset >= this.medicalHistoryOffset?.valueOf() &&
      this.mainScrollOffset < this.attachmentsOffset?.valueOf()
    ) {
      this.currentActive = 2;
    } else if (
      this.mainScrollOffset >= this.attachmentsOffset?.valueOf() &&
      this.mainScrollOffset < this.medicationOffset?.valueOf()
    ) {
      this.currentActive = 3;
    } else if (this.mainScrollOffset >= this.medicationOffset?.valueOf()) {
      this.currentActive = 4;
    } else {
      this.currentActive = 0;
    }
  }

  getPatientDetails(patientId) {
    this.subscriptions.add(
      this.dashboardService.getPatientDetials(patientId).subscribe({
        next: (res: any) => {
          console.log("RESPUESTA DEL PACIENTE");
          console.log(res);
  
          this.patientDetials = res;
          if (res.parent) {
            this.countryCode = res.parent.countryCode;
            this.phoneNumber = res.parent.phoneNumber;
          } else {
            this.countryCode = res.countryCode;
            this.phoneNumber = res.phoneNumber;
          }
          this.calculateAge(this.patientDetials.dateOfBirth);
          this.getMedicalDetails(this.patientId);

        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      })
    );
  }

  getSubscriptionElements() {
    this.subscriptions.add(
      this.modalService.getSubscriptions(
        this.patientId,
      ).subscribe({
        next: (subscriptionElements: any) => {
          console.log("RESPUESTA DE SUBSCRIPCIONES");
          console.log(subscriptionElements);
          this.subscriptionsElements = subscriptionElements;
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      })
    );
  }

  getConsultationList() {
    this.pageParams.patient = this.patientId;
    this.pageParams.status = 5;

    this.subscriptions.add(
      this.dashboardService.getConsultationList(this.pageParams).subscribe({
        next: (res: ConsultationList) => {
          res.data.forEach((consultation) => {
            this.visitHistoryDetails.push(consultation);
          });
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      })
    );
  }

  getMedicalDetails(patientId) {
    this.dashboardService
      .getMedicalHistory({
        patient: patientId,
      } as any)
      .subscribe({
        next: (res: any) => {
          this.medicalHistoryDetails = res;

          if ( this.medicalHistoryDetails?.height ) {
            this.calculateBMI(
              this.medicalHistoryDetails.height,
              this.medicalHistoryDetails.weight,
            );
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          if (error.status == 404) {
            this.medicalHistoryDetails = [];
          } else {
            this.handleError(error);
          }
          this.loading = false;
        },
      });
  }

  calculateAge(dob: any) {
    this.age = moment().diff(dob, "years");
  }

  calculateBMI(height: number, weight: number) {
    if (weight > 0 && height > 0) {
      this.bmi = weight / (((height / 100) * height) / 100);
      this.displayBMI = this.bmi.toFixed(2);
    } else {
      this.bmi = null;
    }
  }

  scrollTo(section) {
    document.querySelector("#" + section).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  get subscription(): string {
    return (
      this.patientDetials?.subscription?.provider?.id ??
      this.patientDetials?.parent?.subscription?.provider?.id ??
      "Sin membresía"
    );
  }

  get isTelecarrier(): boolean {
    return !!this.patientDetials?.subscription?.provider?.provider;
  }

  get providerId(): string {
    return this.subscription !== "Sin membresía"
      ? this.subscription
      : environment.organizationId;
  }

  get canCreateConsult(): boolean {
    const isFreeTrialUsed =
      this.patientDetials && this.patientDetials.isFreeTrialUsed;
    return !(
      this.subscription === "Sin membresía" &&
      !this.isTelecarrier &&
      isFreeTrialUsed
    );
  }

  /*   createContult() {
    if (!this.canCreateConsult) {
      this.translationService
        .get("Cant create consult")
        .subscribe((text: string) => {
          this.sharedService.showErrorMessage(text, "single");
        });
      return;
    }

    const newConsultationBody = {
      parent: this.patientDetials.parent?._id ?? this.patientDetials._id,
      patient: this.patientDetials._id,
      chiefComplaint: this.otherChiefComplaintId,
      otherChiefComplaint: "Solicitar llamada",
      provider: this.providerId,
    };

    this.dashboardService
      .postConsultation(newConsultationBody)
      .subscribe((postResult) => {
        const bookConsultationBody = {
          chiefComplaint: null,
          otherChiefComplaint: null,
          communicationMode: 1,
          isPregnant: 3,
          document: [],
          latitude: "",
          longitude: "",
          provider: this.providerId,
          patient: this.patientDetials.parent?._id ?? this.patientDetials._id,
        };

        this.dashboardService
          .postConsultation(newConsultationBody)
          .subscribe((postResult) => {
            const bookConsultationBody = {
              chiefComplaint: null,
              otherChiefComplaint: null,
              communicationMode: 1,
              isPregnant: 3,
              document: [],
              latitude: "",
              longitude: "",
              provider: this.providerId,
              patient:
                this.patientDetials.parent?._id ?? this.patientDetials._id,
            };
            this.dashboardService
              .bookConsultation(postResult.data._id, bookConsultationBody)
              .subscribe((bookResult) => {
                this.sharedService.showMessage(
                  "Consulta creada exitosamente",
                  "success"
                );

                this.dashboardService
                  .getConsultation(bookResult.data._id)
                  .subscribe(
                    (consultationResponse) => {
                      this.router.navigate(["/consults"], {
                        queryParams: {
                          id: bookResult.data._id,
                          patient: bookResult.data.patient,
                        },
                      });
                    },
                  });
                },
                (error) => {
                  this.sharedService.showErrorMessage(
                    error.error.errors,
                    "single",
                  );
                },
              );
          });
      });
  } */
  createContult() {
    if (!this.canCreateConsult) {
      this.translationService
        .get("Cant create consult")
        .subscribe((text: string) => {
          this.sharedService.showErrorMessage(text, "single");
        });
      return;
    }
    const dialogRef = this.dialog.open(ConsultationOriginComponent, {
      width: "400px",
      disableClose: true,
      autoFocus: false,
      panelClass: "consultation-origin-dialog",
      backdropClass: "consultation-origin-backdrop",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const newConsultationBody = {
          parent: this.patientDetials.parent?._id ?? this.patientDetials._id,
          patient: this.patientDetials._id,
          chiefComplaint: this.otherChiefComplaintId,
          otherChiefComplaint: chiefComplaintCallByDoctor.otherChiefComplaint,
          provider: this.providerId,
          originCall: result,
        };

        this.dashboardService.postConsultation(newConsultationBody).subscribe({
          next: (postResult) => {
            const bookConsultationBody = {
              patient:
                this.patientDetials.parent?._id ?? this.patientDetials._id,
              document: [],
              isPregnant: IsPregnant.NOT_SURE,
              communicationMode: CommunicationMode.PHONE,
              provider: this.providerId,
            };

            this.dashboardService
              .bookConsultation(postResult.data._id, bookConsultationBody)
              .subscribe({
                next: (bookResult) => {
                  this.sharedService.showMessage(
                    "Consulta creada exitosamente",
                    "success",
                  );

                  this.dashboardService
                    .lockConsultation(bookResult.data._id)
                    .subscribe({
                      next: () => {
                        this.router.navigate(["/consults"], {
                          queryParams: {
                            id: bookResult.data._id,
                            patient: bookResult.data.patient,
                          },
                        });
                      },
                      error: (error) => {
                        this.sharedService.showErrorMessage(
                          error.error.errors,
                          "single",
                        );
                      },
                    });
                },
                error: (error) => {
                  this.sharedService.showErrorMessage(
                    error.error.errors,
                    "single",
                  );
                },
              });
          },
          error: (error) => {
            this.sharedService.showErrorMessage(error.error.errors, "single");
          },
        });
      } else {
        return;
      }
    });
  }

  openModalWithAfilliations() {
    this.dialog.open(AffiliationsModalComponent, {
      width: "400px",
      height: "357px",
      disableClose: true,
      autoFocus: false,
      data: this.subscriptionsElements,
      panelClass: "affiliations-modal",
    });
  }

}
