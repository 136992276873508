import { Component, OnInit, Inject } from '@angular/core';
import { 
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA  
} from '@angular/material/dialog';
import { SubscriptionDetails } from 'src/app/core/subscriptions.spec';

@Component({
  selector: 'app-affiliations-modal',
  templateUrl: './affiliations-modal.component.html',
  styleUrls: ['./affiliations-modal.component.scss']
})
export class AffiliationsModalComponent implements OnInit {

  subscriptionsElements: SubscriptionDetails[] = [];

  constructor(
    public dialogRef: MatDialogRef<AffiliationsModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.subscriptionsElements = this.data;
    console.log(this.subscriptionsElements);
  }

  closeModal() {
    this.dialogRef.close();
  }

}
