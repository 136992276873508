import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import clevertap from "clevertap-web-sdk";
import packageJson from "../package.json";
console.log(packageJson.version);

/* if (environment.clevertapActive) { */
// clevertap.init(environment.clevertapId);
/* } */

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
