import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { SocketEvent, SocketEvents } from "../../socketio.service";
import { ConnectionService } from "../../connection.service";
import { DashboardService } from "../../dashboard.service";
import { Router } from "@angular/router";
import moment from "moment";
import { interval, Subscription } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { CredentialsService } from "src/app/core/credentials.service";
import { SharedService } from "src/app/shared/shared.service";
import { SortOrder } from "src/app/shared/pipes/order-by/order-by.model";
import { consultInProcess, Record } from "../MainPagesharedService.service";

export interface Section {
  Name: string;
  Complaint: string;
}

export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: "app-dashboard-specialist",
  templateUrl: "./specialist.component.html",
  styleUrls: ["./specialist.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialistComponent implements OnInit, OnDestroy {
  widthSet = false;
  loadingConsult = true;
  patientId;
  messages: any[] = [];
  messagesSpecialty: any[] = [];
  inProcessConsult: any = [];
  todayConsult: any = [];
  recentConsult: any = [];
  averagetimeConsult: any = [];
  waitingtimeConsult: any = [];
  consultId;
  waitingTime: any = [];
  public singPatientRecord: any;
  display;
  averageconsulttime;
  followUpList: any[] = [];
  consultComplete: boolean = false;
  fileUrl: string;
  loading: boolean = true;
  sortOrder = SortOrder;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private connectionService: ConnectionService,
    private dashboardService: DashboardService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private credentialService: CredentialsService,
    public sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.connectionService.messages.subscribe({
        next: (message) => {
          console.log(message);
          const operations = {
            [SocketEvents.DoctorSpecialistWaitingRoom]: (
              event: SocketEvent,
            ) => {
              this.insertConsultation(this.messages, event);
            },
            [SocketEvents.RemoveFromDoctorSpecialistWaitingRoom]: (
              event: SocketEvent,
            ) => {
              const idx = this.messages.findIndex(
                (x) => x._id === event.consultation,
              );
              if (idx !== -1) {
                this.messages.splice(idx, 1);
              }
            },
          };

          operations[message.operation]?.(message);
          this.loading = false;
        },
      }),
    );

    this.recentConstult();
    this.presentConstult();
    this.inprocessConstult();
    this.averageConsult();
    this.averagewaitingTime();

    this.getNextConsults();
    this.subscriptions.add(
      interval(1000).subscribe({
        next: () => {
          if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
          }
        },
      }),
    );

    this.changeDetector.detectChanges();
    this.sendMessage();
    this.fileUrl = this.credentialService.credentials.fileUrl + "/";
  }

  getNextConsults() {
    this.dashboardService
      .getNextConsultations(this.credentialService.credentials.userDetails._id)
      .subscribe({
        next: (res: any) => {
          const response = res.data.map((item) => ({
            ...item,
            consultType: this.getConsultationType(item),
          }));

          this.followUpList = this.sortConsultByDate(response);
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      });
  }

  sortConsultByDate(consults: any) {
    return consults.sort((a, b) => {
      const dateA = a.date.split("T")[0];
      const dateB = b.date.split("T")[0];
      const momentA = moment(
        `${dateA} ${a.hour}`,
        "YYYY-MM-DD hh:mm:ss",
      ).valueOf();
      const momentB = moment(
        `${dateB} ${b.hour}`,
        "YYYY-MM-DD hh:mm:ss",
      ).valueOf();
      return momentA - momentB;
    });
  }
  secondsToHms(entry) {
    let totalSeconds = Math.floor(
      (new Date().getTime() - new Date(entry.date).getTime()) / 1000,
    );
    var d = Number(totalSeconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    return (
      ("0" + h).slice(-2) +
      ":" +
      ("0" + m).slice(-2) +
      ":" +
      ("0" + s).slice(-2)
    );
  }

  sendMessage() {
    this.connectionService.sendMsg(SocketEvents.DoctorSpecialistWaitingRoom);
  }

  goToConsultationPage(record: Record) {
    this.router.navigate(["/consults"], {
      queryParams: { id: record._id, patient: record.patient._id },
    });
  }

  waitingRoom(record: Record) {
    this.router.navigate(["/consults"], {
      queryParams: { id: record._id, patient: record.patient._id },
    });
  }

  followUpCall(followupItem) {
    this.subscriptions.add(
      this.dashboardService.lockConsultation(followupItem._id).subscribe({
        next: () => {
          this.router.navigate(["/consults"], {
            queryParams: {
              id: followupItem._id,
              patient: followupItem.patient._id,
            },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
    );
  }

  recentConstult() {
    // this.subscriptions.add(
    //   this.mainPageSharedService.GetRecentConsult({
    //     "status[0]": 5,
    //     doctor: this.credentialService.credentials.userDetails._id,
    //     startDate: moment().startOf("day").utc().format(),
    //     endDate: moment().endOf("day").utc().format(),
    //     sort: "-updatedAt",
    //   });
    // );

    this.subscriptions.add(
      this.dashboardService
        .getConsults({
          "status[0]": 5,
          doctor: this.credentialService.credentials.userDetails._id,
          startDate: moment().startOf("day").utc().format(),
          endDate: moment().endOf("day").utc().format(),
          sort: "-updatedAt",
        } as any)
        .subscribe({
          next: (res: any) => {
            this.recentConsult = res["data"].map((item) => ({
              ...item,
              consultType: this.getConsultationType(item),
            }));
          },
          error: (error: HttpErrorResponse) => {
            this.handleError(error);
          },
        }),
    );
  }

  presentConstult() {
    this.subscriptions.add(
      this.dashboardService
        .getConsults({
          "status[0]": 0,
          "status[1]": 1,
          "appointmentType[0]": 3,
          doctor: this.credentialService.credentials.userDetails._id,
          startDate: moment().startOf("day").utc().format("YYYY-MM-DD"),
          endDate: moment().endOf("day").utc().format("YYYY-MM-DD"),
        } as any)
        .subscribe({
          next: (res: any) => {
            this.todayConsult = res["data"];
            this.loadingConsult = false;
            this.changeDetector.detectChanges();
          },
          error: (error: HttpErrorResponse) => {
            this.handleError(error);
          },
        }),
    );
  }

  inprocessConstult() {
    // this.subscriptions.add(
    this.dashboardService
      .getConsults({
        "status[0]": 2,
        "status[1]": 3,
        "status[2]": 4,
        includeFollowUp: 1,
        doctor: this.credentialService.credentials.userDetails._id,
      } as any)
      .subscribe({
        next: (res: any) => {
          this.inProcessConsult = res["data"];
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      });
    // );
  }

  resumeConsult({ _id, patient }: consultInProcess) {
    this.router.navigate(["/consults"], {
      queryParams: { id: _id, patient: patient._id },
    });
  }

  averageConsult() {
    this.subscriptions.add(
      this.dashboardService.getAverageConsult().subscribe({
        next: (res: any) => {
          this.averagetimeConsult = res["data"];

          if (
            this.averagetimeConsult.length > 0 &&
            this.averagetimeConsult[0].consultationTime !== null
          ) {
            var averagetime =
              this.averagetimeConsult[0].consultationTime.toFixed(0);
            var hours = Math.floor(averagetime / 3600);
            var minutes = Math.floor((averagetime - hours * 3600) / 60);
            var seconds = averagetime - hours * 3600 - minutes * 60;

            // round seconds
            seconds = Math.round(seconds * 100) / 100;

            var result = hours < 10 ? "0" + hours : hours;
            result += ":" + (minutes < 10 ? "0" + minutes : minutes);
            result += ":" + (seconds < 10 ? "0" + seconds : seconds);
            this.averageconsulttime = result;
            return result;
          } else {
            this.averageconsulttime = "00:00:00";
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
    );
  }
  averagewaitingTime() {
    this.subscriptions.add(
      this.dashboardService.getwaitTime().subscribe({
        next: (res: any) => {
          this.waitingtimeConsult = res["data"];
          if (
            this.waitingtimeConsult.length > 0 &&
            this.waitingtimeConsult[0].waitingTime != null
          ) {
            const waitingtime =
              this.waitingtimeConsult[0].waitingTime.toFixed(0);
            const hours = Math.floor(waitingtime / 3600);
            const minutes = Math.floor((waitingtime - hours * 3600) / 60);
            let seconds = waitingtime - hours * 3600 - minutes * 60;

            // round seconds
            seconds = Math.round(seconds * 100) / 100;

            let result = hours < 10 ? "0" + hours : hours;
            result += ":" + (minutes < 10 ? "0" + minutes : minutes);
            result += ":" + (seconds < 10 ? "0" + seconds : seconds);
            this.display = result;
            return result;
          } else {
            this.display = "00:00:00";
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
    );
  }

  recentCompleteInfo(info) {
    this.router.navigate(["/consults"], {
      queryParams: { id: info._id, patient: info.patient._id },
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error?.error?.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.message.join(", ");
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }

  private insertConsultation(
    consultationMap: any[],
    message: SocketEvent,
  ): void {
    const doctorProviders: any[] =
      this.credentialService.credentials.userDetails.providers;
    
    console.log("dentro del insert consultation");
    console.log(message.data);
    
      if (Array.isArray(message.data)) {
      // console.log("ingresando al insert consultation");
      message.data.forEach((consultation) => {
        if (
          doctorProviders.some(
            (provider) => provider === consultation.provider._id,
          )
        ) {
          const isInList = consultationMap.some(
            (x) => x._id === consultation._id,
          );
          if (!isInList) {
            consultationMap.push({
              ...consultation,
              consultType: this.getSpecialtyNameByDoctor(
                consultation.medicalSpecialty,
              ),
            });
          }
        }
      });
    } else {
      if (
        doctorProviders.includes(String(message.data.provider._id)) ||
        doctorProviders.includes(message.data.provider._id)
      ) {
        const isInList = consultationMap.some(
          (x) => x._id === message.data._id,
        );
        if (!isInList) {
          consultationMap.push({
            ...message.data,
            consultType: this.getSpecialtyNameByDoctor(
              message.data.medicalSpecialty,
            ),
          });
        }
      }
    }
  }

  public isYounger(message: any) {
    const dateOfBirth = message.patient.dateOfBirth;
    const now = moment();

    if (moment(now).diff(dateOfBirth, "y") < 18) {
      return true;
    } else {
      return false;
    }
  }

  getDate(date: any) {
    return moment(date).add(4, "hour").format("DD-MM-YYYY");
  }

  getTime(hour: string) {
    if (!hour) {
      return "";
    }
    return moment(hour, "HH:mm:ss").format("h:mm A");
  }

  getConsultationType(consult: any) {
    if (consult.appointmentType === 3) {
      return `${this.getSpecialtyName(consult.medicalSpecialty)} - `;
    } else {
      return "";
    }
  }

  getSpecialtyName(specialty: any) {
    
    if ( specialty?.speciality ) {
      return specialty.speciality.speciality.es;
    } 
    else if ( specialty?.subSpeciality ) {
      return specialty.subSpeciality.subSpeciality.es;
    } 
    else {
      return "";
    }
  }

  getSpecialtyNameByDoctor(specialty: any) {
    if (specialty?.speciality) {
      return specialty.speciality.speciality.es + " - ";
    } else if (specialty?.subSpeciality) {
      return specialty.subSpeciality.subSpeciality.es + " - ";
    } else {
      return "";
    }
  }
}
